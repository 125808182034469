import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ButtonsComponent } from "./buttons.component";
import { ButtonModule } from "primeng/button";
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@NgModule({
  imports: [CommonModule, ButtonModule,ProgressSpinnerModule],
  declarations: [ButtonsComponent],
  exports: [ButtonsComponent],
})
export class ButtonsModule {}
