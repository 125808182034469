import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PayoutDataResponse, BankInfo, MarkAsPaidResponse } from "./frontend.models";

@Injectable({
    providedIn: 'root'
})
export class FrontendPayoutService {
    constructor(private http: HttpClient) { }

    getCurrentPayoutData(): Observable<PayoutDataResponse> {
        return this.http.post<PayoutDataResponse>(`/api/frontend/payout_current_data`, null);
    }

    getPastPayoutData(): Observable<PayoutDataResponse> {
        return this.http.post<PayoutDataResponse>(`/api/frontend/payout_past_data`, null);
    }

    getBankInfo(payout_id: number): Observable<BankInfo> {
        const fpData = {
            payout_id,
        };
        return this.http.post<BankInfo>(`/api/frontend/get_bank_info`, fpData);
    }

    markAsPaid(payoutId?: number): Observable<MarkAsPaidResponse> {
        const fpData = {
            payout_id: payoutId,
        };
        return this.http.post<MarkAsPaidResponse>(`/api/frontend/mark_as_paid`, fpData);
    }
}