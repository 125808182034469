import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CategoryPoint, CompanyDashData, DashboardData, LeadOwnerSubResponseData, LeadRoutingResponseData, LeadSalesDashDataParam, LeadSalesResponseData, LeadTechDashData, LeadTechDashDataParam, ReviewsDashboardData, ReviewsData } from "./frontend.models";
import { DateTime } from "luxon";

@Injectable()
export class FrontendDashboardService {
    constructor(private http: HttpClient) { }

    getDashboardData(): Observable<DashboardData> {
        return this.http.post<DashboardData>(`/api/frontend/get_dashboard_data`, null);
    }

    getCompanyDashData(locationList: number[], company_id: number, startDate: DateTime, endDate: DateTime): Observable<CompanyDashData> {
        const fpData = {
            location_list: locationList,
            start_date: startDate,
            end_date: endDate,
            company_id: company_id
        };
        return this.http.post<CompanyDashData>(`/api/frontend/company_dash_data`, fpData);
    }

    getGoogleReviewsData(locationList: number[], company_id: number, startDate: DateTime, endDate: DateTime): Observable<ReviewsData[]> {
      const fpData = {
        location_list: locationList,
        start_date: startDate,
        end_date: endDate,
        company_id: company_id
      };
      return this.http.post<ReviewsData[]>(`/api/frontend/google_reviews`, fpData);
    }

    getReviewsData(locationList: number[], company_id: number, startDate: DateTime, endDate: DateTime): Observable<ReviewsDashboardData[]> {
      const fpData = {
        location_list: locationList,
        start_date: startDate,
        end_date: endDate,
        company_id: company_id
      };
      return this.http.post<ReviewsDashboardData[]>(`/api/frontend/get_review_data`, fpData);
    }

    getCategoryPointsData(company_id: number): Observable<CategoryPoint[]> {
      return this.http.post<CategoryPoint[]>(`/api/frontend/get_category_points`, { company_id });
    }

    getLeadershipTech(company_id: number): Observable<CategoryPoint[]> {
      return this.http.post<CategoryPoint[]>(`/api/frontend/get_category_points`, { company_id });
    }

    saveCategoryPoints(categoryPoints: CategoryPoint[]): Observable<boolean> {
      return this.http.post<boolean>(`/api/frontend/save_category_points`, categoryPoints);
    }

    getRoutingMileageGoal(company_id: number) {
      return this.http.post<{ mileage: number }>(`/api/frontend/get_routing_mileage_goal`, { company_id });
    }

    saveRoutingMileageGoal(company_id: number, mileage_number: number) {
      return this.http.post<boolean>(`/api/frontend/save_routing_mileage_goal`, { company_id, mileage_number });
    }

    getLeadTechDashData(techDashDataParam: LeadTechDashDataParam) {
      return this.http.post<LeadTechDashData>(`/api/frontend/lead_tech_dash`, techDashDataParam);
    }

    getLeadSalesDashData(salesDashDataParam: LeadSalesDashDataParam) {
      return this.http.post<LeadSalesResponseData>(`/api/frontend/lead_sales`, salesDashDataParam);
    }
    
    getLeadRoutingDashData(routingDashDataParam: LeadSalesDashDataParam) {
      return this.http.post<LeadRoutingResponseData>(`/api/frontend/lead_routing`, routingDashDataParam);
    }

    getLeadOwnerDashData(ownerSubDashDataParam: LeadSalesDashDataParam) {
      return this.http.post<LeadOwnerSubResponseData>(`/api/frontend/lead_owner_sub`, ownerSubDashDataParam);
    }
}
