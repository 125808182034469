<div class="page-section">
  <div class="page-section-ref">
    <div class="border m-2">
      <div class="text-white py-2">
        <div class="text-center ">
          <h6 class="text-white -mb-3 mt-3 text-lg font-semibold">Balance</h6>
          <h2 class="text-white text-5xl font-semibold">{{ current_balance | currency:'USD':'symbol':'1.2-2' }}</h2>
          <h6 class="text-white text-lg">Pending: {{ pending_balance | currency:'USD':'symbol':'1.2-2' }}</h6>
        </div>
        <div class="mt-3 mx-2 mb-3">
          <apruv-button [disableProperty]="!cashOut" class="w-full " styleClass="w-full active text-lg"
            (btnClick)="cashOutDialog()">Cash out</apruv-button>
        </div>
        <div class="text-red-50 ml-2" *ngIf="error_message !== ''">
          {{error_message}}
        </div>

        <div class="horizontal-line"></div>

        <div class="justify-content-between  flex mt-3 px-3">
          <div class="text-center  ">
            <h6 class="text-sm font-normal text-white -mb-3">Total earned</h6>
            <h6 class="font-semibold text-white text-lg">{{ total_earned | currency:'USD':'symbol':'1.2-2' }}</h6>
          </div>
          <div class="text-center">
            <h6 class="text-sm font-normal text-white -mb-3">Rating</h6>
            <h6 class="font-semibold text-white text-lg">{{rating_string}} ({{ rating_percentage | number:'1.1-1' }}%)</h6>
          </div>
          <div class="text-center">
            <h6 class="text-sm font-normal text-white -mb-3">Average tip</h6>
            <h6 class="font-semibold text-white text-lg">{{ average_tip | currency:'USD':'symbol':'1.2-2' }}</h6>
          </div>
        </div>
      </div>
    </div>
    <div class="balance-section-ref">
      <div class="mx-3 pt-4 ">
        <h4 class="text-white font-semibold">Activity</h4>
        <ng-container *ngFor="let transactionHistory of transactionsByDate">
          <!-- Date Header -->
          <h6 class="text-white font-semibold text-lg my-4">{{ transactionHistory.date | date:'EEEE, MMMM d, y' }}</h6>

          <!-- Transactions List -->
          <div class="text-white grid my-1 mr-1 my-2" *ngFor="let transaction of transactionHistory.transactions">
            <div class="col-2 mt-2 text-center border-circle">
              <!-- Using the function to get the appropriate SVG for the rating -->
              <img [src]="getRatingImage(transaction.rating, transaction.status_group)" alt="Rating">
            </div>
            <div class="col-8 line-height-2">
              <span class="font-normal text-lg">{{ transaction.first_name}} {{ transaction.last_name}}</span><br>
              <span class="user-details">{{ transaction.transaction_id }}-{{ getTransactionStatus(transaction.status_group)
                }}</span>
            </div>
            <div class="col-2 text-right ">
              <span class="text-lg">{{ transaction.amount | currency:'USD':'symbol':'1.2-2' }}</span>
            </div>
          </div>
        </ng-container>
        <div class="mt-2 mx-3 mb-4">
          <h6 *ngIf="moreActivity" class="text-gray-500 mb-6 mt-3 ml-6 text-lg">No activity...</h6>
        </div>
      </div>
    </div>
    <div class="p-bottom-sticky ">
      <div class="load-more-btn">
        <apruv-button *ngIf="moreActivity && !noTransaction" (click)="loadMoreTransactions()" class="w-full active"
          styleClass="w-full active text-lg">View more activity</apruv-button>
      </div>
    </div>
  </div>
</div>
<apruv-modal [visible]="method_dialog">
  <div class="flex-container mt-4 mb-4">
    <h6 class="text-white flex-grow">Please choose cash out method: </h6>
    <h5 class="text-white cursor-pointer m-0 secondary-color" (click)="method_dialog = false">X</h5>
  </div>
  <div class="text-center">
    <div class="grid">
      <p-card class="col-6 cursor-pointer" (click)="cashOutButton('zelle')">
        <h6 class="text-white font-bold mb-2">Zelle&trade;</h6>
        <span class="text-white font-bold text-sm">Processing Time:</span><br>
        <span class="text-white text-sm">Receive text<sup></sup></span><br>
        <span class="text-white text-sm">Within 24 hours<sup>*</sup></span>
      </p-card>
      <p-card class="col-6 cursor-pointer" [ngClass]="{'disabled-card': !active_plaid}" (click)="cashOutButton('ach')">
        <h6 *ngIf="!active_plaid" class="text-white font-bold mb-2">Standard ACH<sup>**</sup></h6>
        <h6 *ngIf="active_plaid" class="text-white font-bold mb-2">Standard ACH</h6>
        <span class="text-white font-bold text-sm">Processing Time:</span><br>
        <span class="text-white text-sm">3-5 days</span>
      </p-card>
    </div>
  </div>
  <div>
    <span>* If received Mon-Fri before 2PM CST</span>
  </div>
  <div *ngIf="!active_plaid">
    <span>** Standard ACH requires Bank account</span><br>
    <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;to be set up in profile tab</span>
  </div>
</apruv-modal>
<div class="relative">
  <p-progressSpinner *ngIf="is_loading" class="loading-spinner top-50 left-50 mt-8  absolute z-2"></p-progressSpinner>
</div>