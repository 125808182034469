import { Component, OnInit } from '@angular/core';
import { DashboardData, FrontendDashboardService } from '@apruv/services';

@Component({
  selector: 'apruv-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
  data: DashboardData = { companies: 0, integrations: 0, users: 0 };
  errorMessage = "";

  constructor (
    private dashboardService: FrontendDashboardService,
  ) {

  }

  ngOnInit() {
    this.getCounts()
  }

  getCounts() {
    this.dashboardService.getDashboardData().subscribe({
        next: (response: DashboardData) => {
            this.data = response;
        },
        error: (e) => {
            if (e.error instanceof ErrorEvent) {
                // Client-side error
                this.errorMessage = 'Client-side Error - Please refresh page and try again';
            } else if (e.status === 400) {
                // Server-side error
                this.errorMessage = `${e.error.error}. Please try again.`; // Assuming error structure is { error: 'message' }
            }
            console.error(e);
        }
    });
}

}
