import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {Observable, throwError} from "rxjs";
import { Client, VerifyCodeResponse, locationsResponse, CompleteVerifyRequest, VerifyConfig, DetailedLogApiResponse, VerifyDashboardApiResponse, VerifyFlow, VerificationsApiResponse, SalesRepDashDataApiResponse, BillingHistoryApiResponse, DialogWorkflow, Message } from "./frontend.models";
import {catchError} from "rxjs/operators";
import { DateTime } from "luxon";
import { SalesRepAuditApiResponse, SalesRepsApiResponse } from "../Apruv_Api/apruv.models";

@Injectable()
export class FrontendVerifyService {
    private verifyNodeOrigin!: string;

    constructor(private http: HttpClient) {
        this.verifyNodeOrigin = window.location.origin.includes('localhost') ? 'http://localhost:3001' : ''
     }

    searchClient(searchData: any): Observable<{results: Client[]}> {
        return this.http.post<{results: Client[]}>(`/api/verify/search_account`, searchData);
    }

    sendVerificationCode(verificationData: { customer_id: number | string, verification_type: string }) {
        return this.http.post<{success: string}>(`/api/verify/send_code`, verificationData);
    }

    sendVerificationLink(verificationData: { customer_id: number | string, verification_type: string }) {
        return this.http.post<{success: string}>(`/api/verify/send_link`, verificationData);
    }

    verifyCode(verificationData: { verify_link: string }) {
        return this.http.post<VerifyCodeResponse>(`/api/verify/verify_code`, verificationData);
    }

    updateSubscription(customer_id: number) {
        return this.http.post<Client>(`/api/verify/update_customer`, { customer_id });
    }

    searchFieldRoutes(searchData: any): Observable<{results: Client[]}> {
        return this.http.post<{results: Client[]}>(`/api/verify/search_account_crm`, searchData);
    }

    uploadVideo(url: string, file: File): Observable<any> {
      return this.http.put<any>(url, file, {
        reportProgress: true,
        observe: 'events',
        headers: {
            'Content-Type': 'video/webm'
        }
      });
    }

    getSignedUrl(signedUrlData: { company_id: number, customer_id: number, subscription_id: number }): Observable<{signed_url: string}> {
        return this.http.post<{signed_url: string}>(`/api/verify/get_signed_url`, signedUrlData);
    }

    completeVerify(completeVerifyData: CompleteVerifyRequest): Observable<any> {
        return this.http.post<any>(`/api/verify/complete_verify`, completeVerifyData);
    }

    getLocationList(companyId: number): Observable<locationsResponse> {
        const fpData = {
            company_id: companyId,
        };
        return this.http.post<locationsResponse>(`/api/verify/get_locations`, fpData);
    }

    getVerifyConfig(company_id: number) {
        return this.http.post<VerifyConfig>(`/api/verify/verify_config`, { company_id });
    }

    saveDefaultLocation(company_id: number, location_id: number) {
        return this.http.post<any>(`/api/verify/save_default_location`, { company_id, location_id });
    }

    getDetailedLogs(data: { start_date: DateTime, end_date: DateTime, location_id?: number | string }) {
        return this.http.post<DetailedLogApiResponse>(`/api/verify/detailed_logs`, data);
    }


    getVerifyDashboardData(company_id: number) {
        return this.http.post<VerifyDashboardApiResponse>(`/api/frontend/verify_dashboard`, { company_id });
    }

    getVerifyVerifications(data: { start_date: DateTime, end_date: DateTime, company_id: number | string }) {
        return this.http.post<VerificationsApiResponse>(`/api/verify/verifications`, data);
    }


    getVerifyFlow(companyId: number, customerId: number, passcode: string, subscriptionId: number, language: string) {
        return this.http.post<DialogWorkflow>(`${this.verifyNodeOrigin}/api/verify-node/get_verify_flow`, { companyId, customerId, subscriptionId, passcode, language });
    }

    getAudioFilesByWorkflow(companyId: number, customerId: number, passcode: string, message: string, parameters: any, language: string) {
        return this.http.post<string>(`${this.verifyNodeOrigin}/api/verify-node/get_audio_file`, { companyId, customerId, passcode, message, parameters, language });
    }

    getSalesRepDash(company_id: number, location_id: number, start_date: string, end_date: string) {
        return this.http.post<SalesRepDashDataApiResponse>(`/api/verify/get_sales_rep_verify_dash`, { location_id, company_id, end_date, start_date });
    }

    getBillingHistory(company_id: number, start_date: string, end_date: string) {
        return this.http.post<BillingHistoryApiResponse>(`/api/verify/get_billing_history`, { company_id, end_date, start_date });
    }

    getSalesRepAudit(company_id: number, end_date: string, start_date: string, location_ids: number[], sales_rep_ids: number[]) {
        return this.http.post<SalesRepAuditApiResponse>(`/api/verify/get_sales_rep_audit`, { company_id, location_ids, sales_rep_ids, end_date, start_date });
    }

    getSalesReps(company_id: number,  location_id: number[]) {
        return this.http.post<SalesRepsApiResponse>(`/api/verify/get_all_sales_rep`, { company_id, location_id });
    }

    refreshVerifyList(company_id: number, location_id: number) {
        return this.http.post<any>(`/api/verify/refresh_verify_list`, {company_id, location_id});
    }
}
