import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { LayoutModule } from "./layout/layout.module";
import { DropdownModule } from "primeng/dropdown";
import { TabViewModule } from "primeng/tabview";
import { MenuModule } from "primeng/menu";
import { ToastModule } from "primeng/toast";
import { ConfirmationService, MessageService } from "primeng/api";
import { TabMenuModule } from "primeng/tabmenu";
import { InputTextModule } from "primeng/inputtext";
import { ButtonModule } from "primeng/button";
import { TableModule } from "primeng/table";
import { DialogService, DynamicDialogModule } from "primeng/dynamicdialog";
import { InputMaskModule } from "primeng/inputmask";
import { RadioButtonModule } from "primeng/radiobutton";
import { DialogModule } from "primeng/dialog";
import { RatingModule } from "primeng/rating";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { CardModule } from "primeng/card";
import { InputTextareaModule } from "primeng/inputtextarea";
import { DashboardModule } from "./dashboard/dashboard.module";
import { environment } from "@env";
import { KeycloakBearerInterceptor, KeycloakService } from "keycloak-angular";
import { FrontendUserService } from "@apruv/services";
import { LayoutService } from "./layout/service/layout.service";
import { Router } from "@angular/router";
import { catchError, of, switchMap, tap, throwError } from "rxjs";
import {MaintenanceComponent} from "./modules/maintenance/maintenance.component";

function initializeKeycloak(keycloak: KeycloakService, router: Router, userService: FrontendUserService, layoutService: LayoutService) {
  return () => {
    return keycloak.init({
      config: {
        url: environment.keyCloakUrl,
        realm: environment.keyCloakRealm,
        clientId: environment.keyCloakClientId
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html'
      }
    })
    .then((authenticated: any) => {
      console.log('Keycloak initialization completed:', authenticated);
      return new Promise<boolean>((resolve, reject) => {
        userService.getUserInfo().pipe(
          switchMap((res) => {
            if(res?.user_data) layoutService.setActiveUser(res?.user_data[0])
            layoutService.setUserLoadedStatus(true);
            return userService.userSync()
          }),
          tap((res) => {
            if(!res) router.navigate(['/user-not-found'])
            else {
              userService.getUserInfo().subscribe({
                next: (res) => {
                  if(res?.user_data) layoutService.setActiveUser(res?.user_data[0])
                  else router.navigate(['/user-not-found'])
                },
                error: (error) => {
                  reject(error);
                  console.log(error);
                }
              })
            }
          }),
          catchError((error) => {
            reject(error);
            return of(error);
          })
        ).subscribe({
          complete: () => {
            resolve(true);
          }
        });
      })
    })
      .catch((error: any) => {
        console.error('Keycloak initialization error:', error);
        return false;
      });
  }
}



@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    MaintenanceComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        LayoutModule,
        DropdownModule,
        TabViewModule,
        MenuModule,
        ToastModule,
        TabMenuModule,
        ReactiveFormsModule,
        InputTextModule,
        ButtonModule,
        TableModule,
        InputMaskModule,
        DynamicDialogModule,
        RadioButtonModule,
        DialogModule,
        RatingModule,
        FontAwesomeModule,
        CardModule,
        InputTextareaModule,
        DashboardModule,
    ],
  providers: [
    MessageService,
    ConfirmationService,
    DialogService,
    KeycloakService,
    FrontendUserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakBearerInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, Router, FrontendUserService, LayoutService]
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
