import { Component, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { FrontendUserService, UserInfo } from "@apruv/services";
import { environment } from "@env";
import { KeycloakService } from "keycloak-angular";
import { MenuItem } from "primeng/api";
import { BehaviorSubject, Observable } from "rxjs";

@Component({
  selector: "apruv-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent {
  logoutSvg$: Observable<boolean>;
  logoutSvg: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  menuItems: MenuItem[] = [
    { label: 'Apruv Admin', command: () => {
      if(window.location.origin.includes('localhost')) {
        window.location.href = 'http://localhost:4300/adminapp'; //if the admin app is running on port: 4300
      } else {
        window.location.href = `${environment.keyCloakUrl}/adminapp/dash`;
      }
    }, icon: 'pi pi-arrow-circle-right' },
    {
        label: 'Sign Out', command: () => {
            this.handleLogout();
        }, icon: 'pi pi-sign-out'
    },
  ]
  userInfo?: UserInfo;

  constructor(private keycloak: KeycloakService, private router: Router,
    private userService: FrontendUserService) {
    this.logoutSvg$ = this.logoutSvg.asObservable();
   }

  ngOnInit(): void {
    this.handleCheckIsLoggedIn();
    this.getUser();
    this.keycloak.loadUserProfile().then((data) => console.log(data));
  }

  private handleCheckIsLoggedIn() {
    this.keycloak.isLoggedIn().then((value: boolean) => {
      this.logoutSvg.next(value)
    })
  }

  private getUser() {
    this.userService.getUserInfo().subscribe({
      next: (res) => {
        this.userInfo = res;
      },
      error: (error) => {
        console.log(error);
      }
    })
  }

  handleLogout(): void {
    this.keycloak.logout(window.location.origin);
    sessionStorage.removeItem('navigated')
  }
}
