export const environment = {
  production: false,
  apiUrl: 'https://dev.apruv.io/api/auth-dev',
  frontendApiUrl: 'https://dev.apruv.io/api/frontend-dev',
  apruvApiUrl: 'https://dev.apruv.io/api/apruv-dev',
  braintreeApiUrl: 'https://dev.apruv.io/api/braintree-dev',
  companyImageUrl: 'https://dev.apruv.io/api/frontend-dev/upload_company_image',
  keyCloakUrl: 'https://dev.apruv.io',
  keyCloakRealm: 'Apruv-Dev',
  keyCloakClientId: 'apruv-dev',
  keyCloakVerifyClientId: 'apruv-dev-verify',
  appleMerchantIdFile: 'merchantid/dev-merchant-id-association.txt',
  fwdPublicKey: 'test_pkey_5dcKHn1mPiYP3d-ScC2dMWE-f5wjobtgDQHJVNfi'
};
