import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";

@Component({
  selector: "apruv-button",
  templateUrl: "./buttons.component.html",
  styleUrls: ["./buttons.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonsComponent implements OnInit {
  @Output() btnClick = new EventEmitter();
  @Input() disableProperty:boolean=false;
  @Input() isActive: boolean = false;
  _styleClass: string = "p-button-outlined text-white border-blue-900 ";

  constructor() {}
  @Input()
  set styleClass(styleClass: string) {
    this._styleClass += styleClass;
  }

  get styleClass(): string {
    return this._styleClass;
  }
  ngOnInit() {}




}
