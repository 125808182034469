import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DashboardRoutingModule } from "./dashboard-routing.module";
import { LeaderboardComponent } from "./leaderboard/leaderboard.component";
import { ProfileComponent } from "./profile/profile.component";
import { BalanceComponent } from "./balance/balance.component";
import { DashboardFooterComponent } from "./dashboardFooter/dashboard-footer.component";
import {ButtonsModule, HeaderModule, ModalModule,} from "@apruv/components";
import { PlaidComponent } from "./plaid/plaid.component";
import {FeedbackDashboardComponent} from "./feedback/feedback.component";
import { InputSwitchModule } from 'primeng/inputswitch';
import {FormsModule} from "@angular/forms";
import { CardModule } from 'primeng/card';
import {ProgressSpinnerModule} from "primeng/progressspinner";
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from "primeng/dropdown";
import { DashboardContainerComponent } from "./dashboard-container.component";

@NgModule({
  declarations: [
    LeaderboardComponent,
    ProfileComponent,
    BalanceComponent,
    DashboardFooterComponent,
    PlaidComponent,
    FeedbackDashboardComponent,
    DashboardContainerComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    ButtonsModule,
    HeaderModule,
    InputSwitchModule,
    FormsModule,
    ModalModule,
    CardModule,
    ProgressSpinnerModule,
    InputTextareaModule,
    DropdownModule
  ],
  exports: [
    PlaidComponent
  ]
})
export class DashboardModule {}
