import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RadioButtonModule } from "primeng/radiobutton";
import { RadioButtonComponent } from "./radio-button.component";

@NgModule({
  imports: [CommonModule, FormsModule, RadioButtonModule],
  declarations: [RadioButtonComponent],
  exports: [RadioButtonComponent],
})
export class RadioButtonsModule {}
