import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { io, Socket, ManagerOptions, SocketOptions } from 'socket.io-client';
import {Client} from "./frontend.models";
import {HttpClient} from "@angular/common/http";
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  private socket!: Socket;
  soupSocket!: Socket;
  private aiSocket!: Socket;
  websocketOrigin!: string;

  constructor(private http: HttpClient) { 
    this.websocketOrigin = window.location.origin.includes('localhost') ? 'ws://localhost:3001' : ''
  }

  setupConnection(customerId: string, subscriptionId: string, locationId: string) {
    if(this.socket) {
      this.socket.disconnect(); // Ensure previous connections are closed
    }
    this.socket = io(`${this.websocketOrigin}/api/verify-node`, {
      withCredentials: true,
      transports: ['websocket'],
      secure: true,
      query: {
        customerId,
        subscriptionId,
        locationId,
      },
    });
  }

  async setupMediaSoupConnection(customerId: string, subscriptionId: string, locationId: string) {
    if (this.soupSocket) {
      console.log("Disconnecting existing connection...");
      this.soupSocket.disconnect(); // Ensure previous connections are closed
    }
    console.log("Setting up new connection with customerId:", customerId);

    this.soupSocket = io(`${this.websocketOrigin}/api/verify-node/mediasoup`, {
      withCredentials: true,
      transports: ['websocket'],
      secure: true,
      reconnection: true,
      query: {
        customerId,
        subscriptionId,
        locationId,
      },
    });

    return new Promise<void>((resolve, reject) => {
      this.soupSocket.on('connection-success', () => {
        console.log("MediaSoup WebSocket connected.");
        resolve();
      });

      this.soupSocket.on('connect_error', (error) => {
        console.error("Connect error:", error);
        reject(error);
      });

      this.soupSocket.on('error', (error) => {
        console.error("Error in connection:", error);
        reject(error);
      });

      this.soupSocket.on('disconnect', (reason) => {
        console.error("Disconnected:", reason);
        reject(new Error('Disconnected: ' + reason));
      });
    });
  }

  setupAIConnection(customerId: string, subscriptionId: string, locationId: string) {
    if(this.aiSocket) {
      this.aiSocket.disconnect(); // Ensure previous connections are closed
    }
    console.log("setting up connection")
    this.aiSocket = io(`${this.websocketOrigin}/api/verify-node/ai`, {
      withCredentials: true,
      transports: ['websocket'],
      secure: true,
      query: {
        customerId,
        subscriptionId,
        locationId,
      },
    });
  }

  listen(eventName: string): Observable<any> {
    return new Observable((subscriber) => {
      this.socket.on(eventName, (data: any) => {
        console.log(`Received message for event '${eventName}':`, data);
        subscriber.next(data);
      });

      // Cleanup on unsubscribe
      return () => {
        this.socket.off(eventName);
      };
    });
  }

  AIListen(eventName: string): Observable<any> {
    return new Observable((subscriber) => {
      this.aiSocket.on(eventName, (data: any) => {
        console.log(`Received message for event '${eventName}':`, data);
        subscriber.next(data);
      });

      // Cleanup on unsubscribe
      return () => {
        this.aiSocket.off(eventName);
      };
    });
  }

  mediaSouplisten(eventName: string): Observable<any> {
    return new Observable((subscriber) => {
      this.soupSocket.on(eventName, (data: any) => {
        console.log(`Received message for event '${eventName}':`, data);
        subscriber.next(data);
      });

      // Cleanup on unsubscribe
      return () => {
        this.soupSocket.off(eventName);
      };
    });
  }

  // Emit an event with data
  emit(eventName: string, data: any): void {
    if(this.socket) this.socket.emit(eventName, data);
  }

  emitVideo(eventName: string, data: any, index: number): void {
    const payload = {
      buffer: data,
      index: index
    };
    this.socket.emit(eventName, payload);
  }

  // Handle connection open event
  onConnect(): Observable<void> {
    return new Observable<void>((subscriber) => {
      this.socket.on('connect', () => subscriber.next());
    });
  }

  // Handle connection close/disconnect event
  onDisconnect(): Observable<void> {
    return new Observable<void>((subscriber) => {
      this.socket.on('disconnect', () => subscriber.next());
    });
  }

  // Manually open the connection if needed (Socket.IO auto-connects by default)
  connect(): void {
    this.socket.connect();
  }

  // Manually close the connection
  disconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  QTEmit(eventName: string, data: any): void {
    if(this.soupSocket) this.soupSocket.emit(eventName, data);
  }

  emitMediaSoup<T>(eventName: string, callback: (data: T) => void) {
    if(this.soupSocket) this.soupSocket.emit(eventName, (data: T) => {
      callback(data);
    });
  }

  emitMediaSoupError(eventName: string, data: any): void {
    if(this.socket) this.socket.emit(eventName, data);
  }

  emitMediaSoupTransport<T>(eventName: string, data?: any): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      if (this.soupSocket && this.soupSocket.connected) {
        this.soupSocket.emit(eventName, data, (response: T | Error) => {
          if (response instanceof Error) {
            reject(response);
          } else {
            resolve(response);
          }
        });
      } else {
        reject(new Error('WebSocket connection is not established.'));
      }
    });
  }

  isSoupSocketConnected() {
    return this.soupSocket.connected;
  }

  statusEmit(eventName: string, step: number, total: number): void {
    const payload = {
      step: step,
      total: total
    }
    if(this.soupSocket) this.soupSocket.emit(eventName, payload);
  }

  videoUploadOnConnect(): Observable<void> {
    return new Observable<void>((subscriber) => {
      this.soupSocket.on('connect', () => subscriber.next());
    });
  }

  videoUploadOnDisconnect(): Observable<void> {
    return new Observable<void>((subscriber) => {
      this.soupSocket.on('disconnect', () => subscriber.next());
    });
  }
  mediaSoupDisconnect(): void {
    if (this.soupSocket) {
      this.soupSocket.disconnect();
    }
  }

  emitAI(eventName: string, data: any): void {
    if(this.aiSocket) this.aiSocket.emit(eventName, data);
  }

  statusAIEmit(eventName: string, step: number, total: number): void {
    const payload = {
      step: step,
      total: total
    }
    if(this.aiSocket) this.aiSocket.emit(eventName, payload);
  }

  AIOnConnect(): Observable<void> {
    return new Observable<void>((subscriber) => {
      this.aiSocket.on('connect', () => subscriber.next());
    });
  }

  AIOnDisconnect(): Observable<void> {
    return new Observable<void>((subscriber) => {
      this.aiSocket.on('disconnect', () => subscriber.next());
    });
  }
  AIDisconnect(): void {
    if (this.socket) {
      this.aiSocket.disconnect();
    }
  }

  preloadClientState(customerId: string, subscriptionId: string, companyId: string, language: string): Observable<any> {
    const url = '/api/verify-node/preload-clientstate';
    const params = {
      customerId,
      subscriptionId,
      companyId,
      language
    };

    return this.http.get(url, { params });
  }
}
