<div class="layout-topbar">
    <div class="topbar-start">
        <button #menubutton type="button" class="topbar-menubutton p-link p-trigger transition-duration-300" (click)="onMenuButtonClick()">
            <i class="pi pi-bars"></i>
        </button>
    </div>
    <div class="layout-topbar-menu-section">
        <apruv-sidebar></apruv-sidebar>
    </div>
    <div class="topbar-end">
        <ul class="topbar-menu">
            <li class="topbar-item">
                <a (click)="signout()" class="p-button-text p-button-secondary flex-shrink-0">
                    <i class="pi pi-fw pi-sign-out"></i>
                </a>
            </li>                    
        </ul>
    </div>
</div>