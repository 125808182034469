import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EmailSubscription, EmailSubscriptionDTO } from "./frontend.models";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class FrontendEmailSubscriptionService {
    constructor(private http: HttpClient) { }
    getEmailSubscriptions(location_ids: number[], company_id: number): Observable<EmailSubscription[]> {
        return this.http.post<EmailSubscription[]>(`/api/frontend/get_subscriptions`, {location_list: location_ids, company_id: company_id});
    }

    saveEmailSubscriptions(data: { company_id: number, email_subscriptions: any[] }): Observable<EmailSubscription[]> {
        return this.http.post<EmailSubscription[]>(`/api/frontend/save_email_subscription`, data);
    }

    addEmailSubscriptions(subscription: EmailSubscriptionDTO): Observable<EmailSubscription[]> {
        return this.http.post<EmailSubscription[]>(`/api/frontend/add_email_subscription`, subscription);
    }
}
