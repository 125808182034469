import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "@apruv/services";
import {MaintenanceComponent} from "./modules/maintenance/maintenance.component";

const routes: Routes = [
  {
    path: "dashboard",
    loadChildren: () =>
      import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent
  },
  {
    path: "review/:companyName/:appointmentId/feedback",
    loadChildren: () =>
      import("./modules/feedback/feedback.module").then(
        (m) => m.FeedbackModule
      ),
  },
  {
    path: "review/v2/:companyName/:appointmentId/feedback",
    loadChildren: () =>
      import("./modules/feedback-v2/feedback.module").then(
        (m) => m.FeedbackV2Module
      ),
  },
  {
    path: "review/:companyName/:appointmentId",
    loadChildren: () =>
      import("./modules/review/review.module").then((m) => m.ReviewModule),
  },
  {
    path: "review/v2/:companyName/:appointmentId",
    loadChildren: () =>
      import("./modules/review-v2/review-v2.module").then((m) => m.ReviewV2Module),
  },
  {
    path: "review/:companyName/:appointmentId/checkout",
    loadChildren: () =>
      import("./modules/checkout/checkout.module").then(
        (m) => m.CheckoutModule
      ),
  },
  {
    path: "review/v2/:companyName/:appointmentId/checkout",
    loadChildren: () =>
      import("./modules/checkout-v2/checkout.module").then(
        (m) => m.CheckoutV2Module
      ),
  },
  {
    path: "review/:companyName/:appointmentId/share",
    loadChildren: () =>
      import("./modules/share/share.module").then((m) => m.ShareModule),
  },
  {
    path: "review/:companyName/:appointmentId/thank-you",
    loadChildren: () =>
      import("./modules/thank-you/thank-you.module").then(
        (m) => m.ThankYouModule
      ),
  },
  {
    path: "referral",
    loadChildren: () =>
      import("./modules/referral/referral.module").then(
        (m) => m.ReferralModule
      ),
  },
  { path: '.well-known/apple-developer-merchantid-domain-association.txt', redirectTo: 'apple-developer-merchantid-domain-association.txt', pathMatch: 'full' },
  // {
  //   path: "authenticate",
  //   data: {
  //     breadcrumb: "Auth",
  //     allowedRoles: [1],
  //   },
  //   loadChildren: () => import("../../../../libs/components/src/lib/auth/auth.module").then((m) => m.AuthModule),
  // },
  // { path: "login", redirectTo: "/authenticate/login" },
  {
    path: "unknown",
    loadChildren: () =>
      import("./modules/unknown-route/unknown-route.module").then(
        (m) => m.UnknownRouteModule
      ),
  },
  {
    path: "user-not-found",
    loadChildren: () =>
      import("./modules/user-not-found/user-not-found.module").then(
        (m) => m.UserNotFoundModule
      ),
  },
  {
    path: "",
    redirectTo: "/dashboard/balance",
    // redirectTo: "/maintenance",
    pathMatch: "full",
  },
  {
    path: "**",
    redirectTo: "/unknown",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
