import { Component, OnDestroy } from "@angular/core";
import { LayoutService } from "../layout/service/layout.service";
import { UserInfo } from "@apruv/services";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
    selector: 'apruv-dashboard-container',
    template: `
        <div class="layout-preloader-container" *ngIf="!userInfo">
            <div class="layout-preloader">
                <span></span>
            </div>
        </div>
        <div class="page-section">
          <div class="page-section-ref">
            <div class="p-top-sticky">
              <apruv-header></apruv-header>
            </div>
            <ng-container *ngIf="userInfo">
              <router-outlet></router-outlet>
            </ng-container>
            <div class="p-bottom-sticky ">
              <div class="d-flex flex-column px-2">
                  <apruv-dashboard-footer></apruv-dashboard-footer>
              </div>
            </div>
          </div>
        </div>
    `
  })
export class DashboardContainerComponent implements OnDestroy {
  userInfo!: UserInfo | null;
  userInfoSubs!: Subscription;

  constructor (
    private layoutService: LayoutService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.userInfoSubs = this.layoutService.userInfo$.subscribe({
      next: (user: UserInfo | null) => {
        this.userInfo = user;
        if(this.userInfo && this.userInfo?.RoleID === 6) {
          if(window.location.origin.includes('localhost')) {
            window.location.href = 'http://localhost:4400/verify'; //if the apruv app is running on port: 4200
          } else {
            window.location.href = window.location.origin + '/verify';
          }
        }

        if (user && !sessionStorage.getItem('navigated')) {
          sessionStorage.setItem('navigated', 'true');
          this.navigateBasedOnRole(user.RoleID);
        }
      }
    })
  }

  ngOnDestroy(): void {
    this.userInfoSubs.unsubscribe();
  }

  private navigateBasedOnRole(roleID: number): void {
    if(roleID === 1) {
      window.location.href = window.location.origin + '/adminapp/apruv-dash'
    } else if(roleID === 2 || roleID === 4 || roleID === 5) {
      window.location.href = window.location.origin + '/adminapp/dash'
      // window.location.href = window.location.origin + '/maintenance'
    } else {
      this.router.navigate(['/dashboard/balance']);
      // this.router.navigate(['/maintenance']);
    }
  }
}
