import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { tap } from 'rxjs/operators';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from '@env';
import {
  AppointmentResponse,
  BalanceResponse,
  Feedback,
  FeedbackResponse,
  TransactionHistoryResponse,
  FeedbackHistoryResponse,
  TipFeedbackDashResponse,
  TipDashResponse,
  FeedbackDashResponse,
  Profile,
  BalanceInfoResponse,
  CashOutResponse,
  TechboardConfigApiResponse,
  TechboardLocation,
  TechnicianData,
  TechnicianNegativeFeedbacks,
  TechnicianTips,
  TechnicianNegativeFeedbacksResp,
  TechnicianTipsResp,
  TechTipFeedbackDashResponse,
  ReferralSummaryApiResponse,
  AddReferralPostData,
  AddReferralInvitePostData,
  ReferralsApiResponse,
  Referral,
  UpdateReferralStatusPostData,
  AddIncentivePostData,
  GetReferralLinkPostData,
  DecodeReferralLinkResponse,
  ReferralLeaderboardApiResponse,
  RewardAndCashoutHistoryApiResponse,
  UpdateIncentivePostData,
  IncentiveApiResponseData,
  ReferralDashboardApiResponse,
  MultipleIncentivesPostData,
  PaymentIntentResponse,
  PaymentCaptureResponse
} from './apruv.models';
import { LocationList, TechLocation } from '../Frontend_Api/frontend.models';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class ApruvService {
  constructor(private http: HttpClient,
    private messageService: MessageService,
    private router: Router
  ) { }

  getAppointment(appointment_id: string): Observable<AppointmentResponse> {
    const fpData = {
      appointment_id: appointment_id
    };
    const headers = new HttpHeaders();
    return this.http.post<AppointmentResponse>(`/api/apruv/get_appointment_details`, fpData, { headers });
  }
  submitFeedback(feedback: Feedback): Observable<FeedbackResponse> {
    const fpData = {
      feedback_data: feedback
    };
    const headers = new HttpHeaders();
    return this.http.post<FeedbackResponse>(`/api/apruv/submit_feedback`, fpData, { headers });
  }

  balanceOverview(): Observable<BalanceResponse> {
    return this.http.post<BalanceResponse>('/api/apruv/balance_overview', null);
  }

  transactionHistory(Limit: number, Offset: number): Observable<TransactionHistoryResponse> {
    const fpData = {
      limit: Limit,
      offset: Offset
    };
    return this.http.post<TransactionHistoryResponse>('/api/apruv/transaction_history', fpData);
  }

  feedbackHistory(Limit: number, Offset: number, company: boolean): Observable<FeedbackHistoryResponse> {
    const fpData = {
      company: company,
      limit: Limit,
      offset: Offset
    };
    return this.http.post<FeedbackHistoryResponse>('/api/apruv/feedback_history', fpData);
  }

  tipsFeedbackDash(company: boolean, company_id: number, location_list?: number[]): Observable<TipFeedbackDashResponse> {
    const fpData: any = {
      company,
      company_id
    };
    if(company === false && location_list) fpData.location_ids = location_list;
    return this.http.post<TipFeedbackDashResponse>('/api/apruv/technician_leaderboard', fpData);
  }

  tipsDash(company: boolean): Observable<TipDashResponse> {
    const fpData = {
      company: company,
    };
    return this.http.post<TipDashResponse>('/api/apruv/tips_dash', fpData);
  }

  FeedbackDash(company: boolean): Observable<FeedbackDashResponse> {
    const fpData = {
      company: company,
    };
    return this.http.post<FeedbackDashResponse>('/api/apruv/feedback_Dash', fpData);
  }

  GetProfile(): Observable<Profile> {
    return this.http.post<Profile>('/api/apruv/get_user_info', null);
  }

  DeleteBankInfo(): Observable<string> {
    return this.http.post<string>('/api/apruv/delete_bank_info', null);
  }

  CashOutCurrentBalance(): Observable<BalanceInfoResponse> {
    return this.http.post<BalanceInfoResponse>('/api/apruv/cash_out_current_balance', null);
  }

  CashOut(method: string): Observable<CashOutResponse> {
    const fpData = {
      method: method,
    };
    return this.http.post<CashOutResponse>('/api/apruv/cash_out', fpData);
  }

  SubmitBug(bug: string): Observable<boolean> {
    const fpData = {
      bug: bug,
    };
    return this.http.post<boolean>('/api/apruv/submit_bug', fpData);
  }

  getTechboardConfig(company_id: number): Observable<TechboardConfigApiResponse> {
    const fpData = {
      company_id: company_id,
    };
    return this.http.post<TechboardConfigApiResponse>('/api/apruv/get_techboard_config', fpData);
  }

  saveTechboardConfig(data: TechboardLocation): Observable<TechboardConfigApiResponse> {
    return this.http.post<TechboardConfigApiResponse>('/api/apruv/save_techboard_config', data);
  }

  updateTechboardConfig(data: TechboardLocation): Observable<TechboardConfigApiResponse> {
    return this.http.post<TechboardConfigApiResponse>('/api/apruv/update_techboard_config', data);
  }

  getTechnicianData(data: {technician_id: number, company_id: number}): Observable<{ technician_data: TechnicianData }> {
    return this.http.post<{ technician_data: TechnicianData }>(`/api/apruv/get_tech_data`, data);
  }

  getTechnicianNegativeFeedback(data: { technician_id: number, limit: number, offset: number, company_id: number }): Observable<TechnicianNegativeFeedbacksResp> {
    return this.http.post<TechnicianNegativeFeedbacksResp>(`/api/apruv/get_tech_reviews`, data);
  }

  getTechnicianTips(data: { technician_id: number, limit: number, offset: number, company_id: number }): Observable<TechnicianTipsResp> {
    return this.http.post<TechnicianTipsResp>(`/api/apruv/get_tech_tips`, data);
  }

  getTechnicianLocations(): Observable<{ technician_locations: TechLocation[] }> {
    return this.http.post<{ technician_locations: TechLocation[] }>(`/api/apruv/get_tech_locations`, null);
  }

  getTechnicianLeaderboard(company: boolean, company_id: number, location_id?: string): Observable<TechTipFeedbackDashResponse> {
    const fpData: any = {
      company,
      company_id
    };
    if(company === false) fpData.location_ids = [parseInt(location_id!)];
    return this.http.post<TechTipFeedbackDashResponse>('/api/apruv/technician_leaderboard', fpData);
  }

  getReferralSummary(referrer_id: number, referrer_role: string, company_id: number): Observable<ReferralSummaryApiResponse> {
    const fpData: any = {
      referrer_id,
      referrer_role,
      company_id
    };
    return this.http.post<ReferralSummaryApiResponse>('/api/apruv/referral_summary', fpData);
  }

  getReferrals(fpData: {referrer_id: number, referrer_role: string, company_id: number, limit: number, offset: number}): Observable<ReferralsApiResponse> {
    return this.http.post<ReferralsApiResponse>('/api/apruv/referrals', fpData);
  }

  getRewards(fpData: {referrer_id: number, referrer_role: string, company_id: number, limit: number, offset: number}): Observable<RewardAndCashoutHistoryApiResponse> {
    return this.http.post<RewardAndCashoutHistoryApiResponse>('/api/apruv/rewards_history', fpData);
  }

  getReferralLeaderboard(referralData: { referrer_id?: number, referrer_role?: string, company_id?: number, limit?: number, sort_order?: string, sort_by?: string }): Observable<ReferralLeaderboardApiResponse> {
    return this.http.post<ReferralLeaderboardApiResponse>('/api/apruv/referral_leaderboard', referralData);
  }

  getReferralDashboard(company_id: number): Observable<ReferralDashboardApiResponse> {
    return this.http.post<ReferralDashboardApiResponse>('/api/apruv/referral_dashboard', { company_id });
  }

  addReferral(data: AddReferralPostData): Observable<Referral> {
    return this.http.post<Referral>('/api/apruv/add_referral', data);
  }

  addReferralInvite(data: AddReferralInvitePostData): Observable<any> {
    return this.http.post<any>('/api/apruv/referral_invites', data);
  }

  updateReferralStatus(data: UpdateReferralStatusPostData): Observable<any> {
    return this.http.post<any>('/api/apruv/referral_workflow', data);
  }

  getIncentives(data: { company_id: number, multiple?: boolean }) {
    return this.http.post<IncentiveApiResponseData>('/api/apruv/incentives', data);
  }

  addIncentive(data: AddIncentivePostData) {
    return this.http.post<IncentiveApiResponseData>('/api/apruv/add_incentive', data);
  }

  updateIncentive(data: UpdateIncentivePostData) {
    return this.http.post<IncentiveApiResponseData>('/api/apruv/update_incentive', data);
  }

  multipleIncentive(data: MultipleIncentivesPostData) {
    return this.http.post<{success: boolean}>('/api/apruv/update_multiple_incentives', data);
  }

  getReferralLink(data: GetReferralLinkPostData): Observable<any> {
    return this.http.post<any>('/api/apruv/referral_link', data);
  }

  decodeReferralLink(referral_link: string): Observable<DecodeReferralLinkResponse> {
    return this.http.post<DecodeReferralLinkResponse>('/api/apruv/decode_link', { referral_link })
    .pipe(
      catchError((error: any) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error?.error })
        this.router.navigate(['/referral/invalid']);
        return throwError(error)
      })
    );
  }

  sendCashoutVerificationCode(params: { email: string, phone: string, company_id: number}): Observable<{success: string}> {
    return this.http.post<{success: string}>('/api/apruv/cash_out_code', params);
  }

  cashoutVerification(params: { referrer_id: number, verification_code: string, company_id: number}): Observable<any> {
    return this.http.post<any>('/api/apruv/cash_out_verification', params);
  }

  cashoutIncentives(params: GetReferralLinkPostData): Observable<any> {
    return this.http.post<any>('/api/apruv/cash_out_incentives', params);
  }

  createPaymentIntent(amount: number) {
    return this.http.post<PaymentIntentResponse>('/api/apruv/payment_intent', { amount });
  }

  capturePayment(payment_intent: string, payment_method: any) {
    return this.http.post<PaymentCaptureResponse>('/api/apruv/payment_capture', { payment_intent, payment_method });
  }
}



