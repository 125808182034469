import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
// import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '@env';
import { BraintreeClientTokenResponse, BraintreePaymentResponse } from './braintree.models';


@Injectable({
  providedIn: 'root'
})
export class BraintreeService {
  private braintreeApiUrl = environment.braintreeApiUrl;

  constructor(private http: HttpClient) { }

  getBraintreeToken(): Observable<BraintreeClientTokenResponse> {
    const headers = new HttpHeaders();
    return this.http.post<BraintreeClientTokenResponse>(`/api/braintree/client_token`, null, { headers });
  }

  sendPayment(nonce: string, amount: number, customer: number): Observable<BraintreePaymentResponse> {
    console.log(customer)
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    let body = new HttpParams();
    body = body.set('payment_method_nonce', nonce);
    body = body.set('amount', amount);
    body = body.set('customer', customer);
    return this.http.post<BraintreePaymentResponse>(`/api/braintree/payment`, body, { headers });
  }

}
