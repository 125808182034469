import { Component, OnInit } from "@angular/core";
import {Subscription} from "rxjs";
import {
  ApruvService,
  FeedbackDash, FeedbackDashRank,
  FrontendCompanyService,
  LocationList,
  TechLocation,
  TechTipFeedbackDashRank,
  TipDash, TipDashRank,
  TipFeedbackDash,
  TipFeedbackDashRank,
  locationsResponse
} from "@apruv/services";
import { KeycloakService } from "keycloak-angular";
import { LayoutService } from "../../layout/service/layout.service";

interface JwtPayload {
  user_id: number;
  email: string;
  role_id: number;
  company_id: number;
  exp: number;
  external_user_id: number;
}

@Component({
  selector: "apruv-leaderboard",
  templateUrl: "./leaderboard.component.html",
  styleUrls: ["./leaderboard.component.css"],
})
export class LeaderboardComponent implements OnInit {
  technician_id?: string;
  company_id?: number;
  today = new Date();
  dateOptions = { weekday: 'long' as const, month: 'long' as const, day: 'numeric' as const };
  formattedDate = '';
  company = false;
  tfTopTechnicians: TipFeedbackDash[] = [];
  tfUserTechnician: TipFeedbackDash[] = [];
  tfOthers: TipFeedbackDash[] = [];
  locations: TechLocation[] = [];
  selectedLocation?: string;
  private userInfoSubscription!: Subscription;

  constructor(
    private apruvService: ApruvService,
    private layoutService: LayoutService
  ) {
    this.userInfoSubscription = this.layoutService.userInfo$.subscribe(res => {
      this.technician_id = res?.KeycloakID;
      this.company_id = res?.CompanyID;
    });
    this.formattedDate = this.today.toLocaleDateString('en-US', this.dateOptions);
    this.formattedDate = this.today.toLocaleDateString('en-US', this.dateOptions);
  }

  ngOnInit(): void {
    this.loadLocationList();
  }

  onToggleChange() {
    if(this.company !== true) {
      if(!this.selectedLocation) {
        this.tfTopTechnicians = [];
        this.tfUserTechnician = [];
        this.tfOthers = [];
        return;
      } else {
        this.selectedLocation = `${this.locations[0]?.location_id}`
      }
    }
    if(this.selectedLocation) this.setTipFeedbackData()
  }

  public setTipFeedbackData(): Subscription {
    return this.apruvService
      .getTechnicianLeaderboard(this.company, this.company_id!, this.selectedLocation!)
      .subscribe((res) => {
        const tipsFeedbackMap: { [key: number]: TechTipFeedbackDashRank } = {};

        res.tips_feedback.forEach(tipsFeedbackDash => {
          const rankGroup = tipsFeedbackDash.rank_group;

          if (!tipsFeedbackMap[rankGroup]) {
            tipsFeedbackMap[rankGroup] = {
              rank_group: rankGroup,
              technician_leaderboard: []
            };
          }
          tipsFeedbackMap[rankGroup].technician_leaderboard.push(...tipsFeedbackDash.technician_leaderboard);
        });

        // Sort by rankGroup
        const tipsFeedbackByRankGroup = Object.values(tipsFeedbackMap).sort((a, b) => a.rank_group - b.rank_group);
        this.tfTopTechnicians = tipsFeedbackByRankGroup.find(group => group.rank_group === 1)?.technician_leaderboard || [];
        this.tfUserTechnician = tipsFeedbackByRankGroup.find(group => group.rank_group === 2)?.technician_leaderboard || [];
        this.tfOthers = tipsFeedbackByRankGroup.find(group => group.rank_group === 0)?.technician_leaderboard || [];
      });
  }

  private loadLocationList() {
    // assuming the service has a method `getCompanyList` that takes a company_id
    this.apruvService.getTechnicianLocations().subscribe({
      next: (res: { technician_locations: TechLocation[] }) => {
        this.locations = res.technician_locations;
        if(!this.locations?.length) this.company = true;
        if(this.selectedLocation) this.setTipFeedbackData()
      },
      error: (err: any) => {
        console.error('Error fetching company list:', err);
      }
    });
  }
}


