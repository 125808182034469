import { DropdownOptions } from "./frontend.models";

export const ROLE_OPTS: DropdownOptions[] = [
    { label: 'SuperUser', value: 1, valueStr: 'SuperUser' },
    { label: 'Manager', value: 2, valueStr: 'Manager'},
    { label: 'Technician', value: 3, valueStr: 'Technician'},
    { label: 'Customer Service', value: 5, valueStr: 'Customer Service'},
    { label: 'Admin', value: 4, valueStr: 'Admin'}
]

export const STATUS_OPTS: DropdownOptions[] = [
    { label: 'Active', value: 'Active' },
    { label: 'Inactive', value: 'Inactive' },
]

export const STATUS_OPTS_BOOL: DropdownOptions[] = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
]

export const SUBSCRIPTION_TYPE: DropdownOptions[] = [
    { label: 'Company', value: 'Company' },
    { label: 'Location', value: 'Location' },
]