<div class="layout-container" [ngClass]="containerClass">
    <p-toast></p-toast>
    <p-confirmDialog></p-confirmDialog>
    <apruv-topbar></apruv-topbar>
    <div class="layout-content-wrapper">
        <div class="layout-content">
            <apruv-breadcrumb></apruv-breadcrumb>
            <router-outlet></router-outlet>
        </div>
    </div>

    <div class="layout-mask"></div>
</div>