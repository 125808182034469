import { Component, ElementRef, ViewChild } from '@angular/core';
import { LayoutService } from '../service/layout.service';
import { KeycloakService } from 'keycloak-angular';

@Component({
    selector: 'apruv-topbar',
    templateUrl: 'topbar.component.html'
})
export class TopbarComponent {
    @ViewChild('menubutton') menuButton!: ElementRef;

    activeItem!: number;
    constructor(
        public layoutService: LayoutService, 
        public el: ElementRef,
        private keycloak: KeycloakService
        ) {
    }

    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    onSidebarButtonClick() {
        this.layoutService.showSidebar();
    }

    onConfigButtonClick() {
        this.layoutService.showConfigSidebar();
    }

    signout() {
        this.keycloak.logout(window.location.origin);
    }
}