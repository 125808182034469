<div class="page-section">
  <div class="page-section-ref page-container">
    <div class=" m-2">
      <div class="text-center pt-5 ">
    <!--        <h6 class="text-white font-semibold text-lg -mb-3">{{formattedDate}}</h6>-->
        <h2 class="text-white font-semibold text-5xl">Feedback</h2>
      </div>
      <div class="text-white py-2">
          <div class="horizontal-line"></div>
      </div>
    </div>

    <div class="balance-section-ref">
      <div class="mx-3 pt-4 pb-5">
        <h4 class="text-white font-semibold">Activity</h4>
          <ng-container *ngFor="let feedbackHistory of feedbackByDate">
            <!-- Date Header -->
            <h6 class="text-white font-semibold text-lg my-4">{{ feedbackHistory.date | date:'EEEE, MMMM d, y' }}</h6>

            <!-- Transactions List -->
            <div class="text-white grid my-1 mr-1 my-2" *ngFor="let feedback of feedbackHistory.feedback">
              <div class="col-2 mt-2 text-center border-circle">
                <!-- Using the function to get the appropriate SVG for the rating -->
                <img [src]="getRatingImage(feedback.rating)" alt="Rating">
              </div>
              <div class="col-8 line-height-2">
                <span class="font-normal text-lg">{{ feedback.first_name}} {{ feedback.last_name}} - {{feedback.fr_appointment_id}}</span><br>
                <span class="user-details">{{ feedback.comments }}</span>
              </div>
            </div>
          </ng-container>
      </div>
    </div>
    <div class="p-bottom-sticky ">
      <div class="load-more-btn">
        <apruv-button (click)="loadMoreTransactions()" class="w-full active mb-5" styleClass="w-full active text-lg">View more activity</apruv-button>
      </div>
    </div>
  </div>
</div>