<div class="pb-4">
  <div class="text-center pt-5 ">
      <h6 class="text-white font-semibold text-lg -mb-3">{{formattedDate}}</h6>
      <h2 class="text-white font-semibold text-5xl">Leaderboard</h2>
  </div>
  <div class="horizontal-line"></div>
    <div class="flex justify-center align-items-center justify-content-center">
      <div class="align-items-center flex mt-3 px-3">
        <div class="text-center mr-3 flex">
          <h6 class="text-sm font-normal text-white mb-0">By Location</h6>
        </div>
        <div class="text-center">
          <p-inputSwitch [(ngModel)]="company" (ngModelChange)="onToggleChange()"></p-inputSwitch>
        </div>
        <div class="text-center ml-3">
          <h6 class="text-sm font-normal text-white mb-0">By Company</h6>
        </div>
      </div>
    </div>
    <div class="p-formgrid grid p-fluid mt-3 px-5" *ngIf="!company">
      <div class="field col-12">
        <p-dropdown appendTo="body" class="inputText" styleClass="w-full borderless"
        [options]="locations" id="locationId" [(ngModel)]="selectedLocation" (ngModelChange)="setTipFeedbackData()" optionValue="location_id"
        optionLabel="name" />
      </div>
    </div>
    <div class="horizontal-line mt-2"></div>
    <div class="dark-border px-2 py-3 mx-2 mt-4">
      <div class="text-white flex flex-wrap" *ngFor="let item of tfTopTechnicians" [ngClass]="item.id === technician_id ? 'border-rounded' : ''">
          <div class="p-1 col-1 mt-2 text-center" style="flex: 9%;">
              <h6 [ngClass]="item.rank === 1 ? 'active-badge' : ''" class="badge px-3 text-center border-circle text-white">
                  {{item.rank}}</h6>
          </div>
          <div class="flex-item p-1 col-2" style="flex: 17%;">
              <img src="/assets/leaderboard/profile.png" alt="">
          </div>
          <div class="flex-item p-1 col-7 line-height-2 mt-1" style="flex: 56%;">
              <span class="font-normal text-lg">{{item.first_name}} {{item.last_name}}</span><br>
              <span class="secondary-color text-sm">{{item.total_ratings}} review(s) {{item.total_transactions}} tip(s)</span><br>
              <span *ngIf="company" class="secondary-color text-sm">{{item.location_name}}</span>
          </div>
          <div class="flex-item p-1   col-2 mt-3 mr-1" style="flex: 16%;">
              <span class="text-lg" [ngClass]="item.rank === 1 ? 'active-percentage' : ''">{{item.normalized_score | number:'1.2-2' }}%</span>
          </div>
      </div>
  </div>
  <div class="py-2 mt-4 leaderboard-section-ref">
    <div class="px-2 py-3 mx-2">
      <div class="text-white flex flex-wrap" *ngFor="let item of tfUserTechnician" [ngClass]="item.id === technician_id ? 'border-rounded' : ''">
        <div class="p-1 col-1 mt-2 text-center" style="flex: 9%;">
          <h6 [ngClass]="item.rank === 1 ? 'active-badge' : ''" class="badge px-3 text-center border-circle text-white">
            {{item.rank}}</h6>
        </div>
        <div class="flex-item p-1 col-2" style="flex: 17%;">
          <img src="/assets/leaderboard/profile.png" alt="">
        </div>
        <div class="flex-item p-1 col-7 line-height-2 mt-1" style="flex: 56%;">
          <span class="font-normal text-lg">{{item.first_name}} {{item.last_name}}</span><br>
          <span class="secondary-color text-sm">{{item.total_ratings}} review(s) {{item.total_transactions}} tip(s)</span><br>
          <span *ngIf="company" class="secondary-color text-sm">{{item.location_name}}</span>
        </div>
        <div class="flex-item p-1   col-2 mt-3 mr-1" style="flex: 16%;">
          <span class="text-lg" [ngClass]="item.rank === 1 ? 'active-percentage' : ''">{{item.normalized_score | number:'1.2-2' }}%</span>
        </div>
      </div>
    </div>
    <div class="px-2 py-3 mx-2">
      <div class="text-white flex flex-wrap" *ngFor="let item of tfOthers" [ngClass]="item.id === technician_id ? 'border-rounded' : ''">
        <div class="p-1 col-1 mt-2 text-center" style="flex: 9%;">
          <h6 [ngClass]="item.rank === 1 ? 'active-badge' : ''" class="badge px-3 text-center border-circle text-white">
            {{item.rank}}</h6>
        </div>
        <div class="flex-item p-1 col-2" style="flex: 17%;">
          <img src="/assets/leaderboard/profile.png" alt="">
        </div>
        <div class="flex-item p-1 col-7 line-height-2 mt-1" style="flex: 56%;">
          <span class="font-normal text-lg">{{item.first_name}} {{item.last_name}}</span><br>
          <span class="secondary-color text-sm">{{item.total_ratings}} review(s) {{item.total_transactions}} tip(s)</span><br>
          <span *ngIf="company" class="secondary-color text-sm">{{item.location_name}}</span>
        </div>
        <div class="flex-item p-1   col-2 mt-3 mr-1" style="flex: 16%;">
          <span class="text-lg" [ngClass]="item.rank === 1 ? 'active-percentage' : ''">{{item.normalized_score | number:'1.2-2' }}%</span>
        </div>
      </div>
    </div>
  </div>
</div>