import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
      RouterModule.forChild([
        // { path: 'error', loadChildren: () => import('./error/error.module').then((m) => m.ErrorModule) },
        {
          path: "access",
          loadChildren: () =>
            import("./accessdenied/accessdenied.module").then(
              (m) => m.AccessdeniedModule
            ),
        },
        {
          path: "login",
          loadChildren: () =>
            import("./login/login.module").then((m) => m.LoginModule),
        },
        {
          path: "forgot-password",
          loadChildren: () =>
            import("./forgot-password/forgot-password.module").then(
              (m) => m.ForgotPasswordModule
            ),
        },
        {
          path: "update-password",
          loadChildren: () =>
            import("./update-password/update-password.module").then(
              (m) => m.UpdatePasswordModule
            ),
        },
        {
          path: "verification",
          loadChildren: () =>
            import("./verification/verification.module").then(
              (m) => m.VerificationModule
            ),
        },
        {
          path: "registration",
          loadChildren: () =>
            import("./sales-registration/sales-registration.module").then(
              (m) => m.SalesRegistrationModule
            ),
          // loadChildren: () =>
          //   import("./registration/registration.module").then(
          //     (m) => m.RegistrationModule
          //   ),
        },
        { path: "**", redirectTo: "/unknown" },
      ]),
    ],
    exports: [RouterModule],
  })
export class AuthRoutingModule {}
