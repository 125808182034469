import { Component } from '@angular/core';

@Component({
  selector: 'apruv-maintenance',
  template: `
        <div class="maintenance-page">
            <h1>Apruv is currently Under Maintenance</h1>
            <p>We are currently performing scheduled maintenance. We should be back shortly. Thank you for your patience.</p>
        </div>
    `,
  styles: [`
        .maintenance-page {
            text-align: center;
            padding: 50px;
            font-family: Arial, sans-serif;
            background-color: #272459;
            color: white;
        }
        h1 {
            font-size: 2em;
        }
        p {
            font-size: 1.2em;
        }
    `]
})
export class MaintenanceComponent {}
