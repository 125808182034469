import { OnInit } from '@angular/core';
import { Component } from '@angular/core';

@Component({
    selector: 'apruv-menu',
    templateUrl: 'menu.component.html'
})
export class MenuComponent implements OnInit {
    model: any[] = [];

    ngOnInit() {
        this.model = [
            {
                label: 'Dashboards',
                icon: 'pi pi-home',
                items: [
                    {
                        label: 'Dashboard',
                        icon: 'pi pi-fw pi-home',
                        routerLink: ['/']
                    }
                ]
            }
        ];
    }
}
