import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse, HttpParams
} from '@angular/common/http';

import { Observable, throwError, EMPTY, from } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class FrontendHttpConfigInterceptor implements HttpInterceptor {
    constructor() {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const paramsObject: any = {};
        request.params.keys().forEach((x) => {
            const value: any = request.params.getAll(x); // used to check if the params has multiple values of not
            if(value.length > 1) { // if greater than -> use the getAll to get all elements
                paramsObject[x] = request.params.getAll(x);
            } else { // if not just get the first element
                paramsObject[x] = request.params.get(x);
            }
        });
        const params = removeEmpty(paramsObject);

        const token = sessionStorage.getItem('jwt_token');
        if (token && request.url.includes('/api/frontend')) {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token),
                params: new HttpParams({fromObject: params}) });
        }

        if (!request.headers.has('Content-Type') && !(request.body instanceof FormData)) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json'),
                params: new HttpParams({fromObject: params}) });
        }

        if (!request.headers.has('Accept')) {
            request = request.clone({ headers: request.headers.set('Accept', 'application/json'),
                params: new HttpParams({fromObject: params}) });
        }

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
              // if (event instanceof HttpResponse) {}
              return event;
            }),
            catchError((error: HttpErrorResponse) => {
            //   this.globalService.handleError(error);
              return throwError(error);
            } ));
    }
}

function removeEmpty(obj: any) {
    const _obj = JSON.parse(JSON.stringify(obj));
    Object.keys(_obj).forEach(key => (_obj[key] == null || _obj[key] === '') && delete _obj[key]);
    return _obj;
}
