import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {firstValueFrom, Observable} from "rxjs";


interface LinkTokenResponse {
  link_token: string;
}

@Injectable({
  providedIn: 'root'
})
export class PlaidService {
  constructor(private http: HttpClient) {}

  async createLinkToken(): Promise<string> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${sessionStorage.getItem("jwt_token")}`);
    const response$ = this.http.post<LinkTokenResponse>('/api/apruv/plaid_create_link_token', null, { headers });
    const response = await firstValueFrom(response$);

    if (!response || !response.link_token) {
      throw new Error('Expected link_token in response, but it was not present.');
    }
    return response.link_token;
  }

  exchangePublicToken(publicToken: string) {
    const fpData = {
      public_token: publicToken
    };
    const headers = new HttpHeaders().set('Authorization', `Bearer ${sessionStorage.getItem("jwt_token")}`);
    return this.http.post<LinkTokenResponse>('/api/apruv/plaid_token_exchange', fpData, { headers });
  }

}
