import { Component, OnInit, Input, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";

const noop = () => {};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RadioButtonComponent),
  multi: true,
};

@Component({
  selector: "app-radio-button",
  templateUrl: "./radio-button.component.html",
  styleUrls: ["./radio-button.component.css"],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class RadioButtonComponent implements OnInit, ControlValueAccessor {
  modalVal: any;
  @Input() name: string = "";
  @Input() value: string = "";
  @Input() id: string = "";
  @Input() inputId: string = "";
  @Input() cssClass: string = "";

  constructor() {}
  //Placeholders for the callbacks which are later provided
  //by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  //get accessor
  get modalValue(): any {
    return this.modalVal;
  }

  //set accessor including call the onchange callback
  set modalValue(v: any) {
    if (v !== this.modalVal) {
      this.modalVal = v;
      this.onChangeCallback(v);
    }
  }

  //From ControlValueAccessor interface
  writeValue(value: any) {
    if (value !== this.modalVal) {
      this.modalVal = value;
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  ngOnInit() {}
}
