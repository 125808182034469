import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TechnicianDetail } from "./frontend.models";

@Injectable()
export class FrontendTechnicianService {
    constructor(private http: HttpClient) { }

    getTechnicianDetails(technician_id: number): Observable<TechnicianDetail> {
        const fpData = { technician_id };
        return this.http.post<TechnicianDetail>(`/api/frontend/technician_details`, fpData);
    }
}