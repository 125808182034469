export interface AppointmentResponse {
    appointmentData: Appointment;
}

export interface Appointment {
  AppointmentId: number;
  IsInitial: number;
  CustomerFirstName: string;
  TechnicianFirstName: string;
  IsRated: number;
}

export interface Feedback {
  AppointmentId: number;
  Rating: number;
  Feedback: string;
}

export interface FeedbackResponse {
  status: boolean
}

export interface BalanceOverview {
  status_1_total: string;
  status_0_total: string;
  status_minus1_total: string;
}

export interface TransactionsOverview {
  total_transactions: number;
  total_amount: string;
  average_amount: string;
}

export interface RatingsOverview {
  total_ratings: number;
  rating_percentage: string;
}

export interface BalanceResponse {
  plaid: boolean;
  balance: BalanceOverview;
  transaction: TransactionsOverview;
  rating: RatingsOverview;
}

export interface BalanceInfoResponse {
  balance: BalanceOverview;
}

export interface CashOutResponse {
  cashedOut: boolean;
}

// models.ts

export interface Transaction {
  id: number;
  transaction_id: string;
  amount: string;
  transaction_date: Date;
  rating: number;
  status_group: number;
  first_name: string;
  last_name: string;
}

export interface TransactionHistory {
  date: string;
  transactions: Transaction[];
}

export interface TransactionHistoryResponse {
  transaction_count: number;
  transaction_history: TransactionHistory[];
}

export interface Feedback {
  rating_id: number;
  appointment_id: number;
  fr_appointment_id: number;
  first_name: string;
  last_name: string;
  rating: number;
  comments: string;
}

export interface FeedbackHistory {
  date: string;
  feedback: Feedback[];
}

export interface FeedbackHistoryResponse {
  feedback_count: number;
  feedback_history: FeedbackHistory[];
}

export interface TipFeedbackDash {
  rank: number
  id: string
  keycloak_id: boolean
  email: boolean
  location_name: string
  first_name: string
  last_name: string
  total_transactions: number
  total_amount: string
  average_amount: string
  total_ratings: number
  rating_percentage: string
  normalized_score: string
}

export interface TipFeedbackDashRank {
  rank_group: number;
  tips_feedback_dash: TipFeedbackDash[];
}

export interface TechTipFeedbackDashRank {
  rank_group: number;
  technician_leaderboard: TipFeedbackDash[];
}

export interface TechTipFeedbackDashResponse {
  tips_feedback: TechTipFeedbackDashRank[];
}

export interface TipFeedbackDashResponse {
  tips_feedback: TechTipFeedbackDashRank[];
}

export interface TipDash {
  rank: number;
  location_name: string;
  technician_id: number;
  first_name: string;
  last_name: string;
  total_transactions: number;
  total_amount: number;
  average_amount: number;
  normalized_score: number;
}

export interface TipDashRank {
  rank_group: number;
  tips_dash: TipDash[];
}

export interface TipDashResponse {
  tips: TipDashRank[];
}

export interface FeedbackDash {
  rank: number;
  location_name: string;
  technician_id: number;
  first_name: string;
  last_name: string;
  total_ratings: number;
  rating_percentage: number;
  normalized_score: number;
}

export interface FeedbackDashRank {
  rank_group: number;
  feedback_dash: FeedbackDash[];
}

export interface FeedbackDashResponse {
  feedback: FeedbackDashRank[];
}

export interface Profile {
  mask: string;
  name: string;
  type: string;
  user_name: string;
  status: boolean;
}

export interface TechboardConfigApiResponse {
  techboard_config: TechboardConfig
}

export interface TechboardConfig {
  start_date: string
  rolling_date: number
  weights: TechboardWeights
  metrics: TechboardMetric[]
  locations: TechboardLocation[]
}

export interface TechboardMetric {
  id: number
  metric: string
  impact: string
}

export interface TechboardLocation {
  start_date: string
  rolling_date: number
  company_id?: number
  location_id: number
  company_override: boolean
  weights: TechboardWeights;
  metrics?: TechboardMetric[]
}

export interface TechboardWeights {
  [key: string]: string;
}

export interface TechboardMetric {
  id: number
  metric: string
  impact: string
}


export interface TechnicianData {
  id: number
  first_name: string
  last_name: string
  negative_review: number
  positive_review: number
  average_review: string
  total_tips: number
  total_tip_amount: string
  total_reviews?: number
  full_name?: string
  average_tip: number;
}

export interface TechnicianNegativeFeedbacksResp {
  limit: number
  offset: number
  technician_feedback: TechnicianNegativeFeedbacks[]
  total_records: number
}
export interface TechnicianNegativeFeedbacks {
  first_name: string
  last_name: string
  appointment_id: string
  rating: string
  comment: string
  date_created?: string
  customer_id: number
}

export interface TechnicianTipsResp {
  limit: number
  offset: number
  technician_tips: TechnicianTips[]
  total_records: number
}

export interface TechnicianTips {
  first_name: string
  last_name: string
  full_name?: string
  appointment_id: string
  tip: string
  date_created?: string
}
export interface ReferralSummaryApiResponse {
  referral_summary: ReferralSummary
}

export interface ReferralSummary {
  total_won: number
  total_referral: number
  total_invites: number
  bonus_earned: string
  company_logo: string
  reward_balance: number
  total_cashout: number
  conversion_rate: number
}

export interface ReferralsApiResponse {
  limit: number
  offset: number
  referrals: Referral[]
  total_records: number
}

export interface Referral {
  first_name: string
  date_created: string
  referral_status: string
  bonus_earned: string
}

export interface AddReferralPostData {
  first_name: string
  last_name: string
  phone: string
  email: string
  referrer_id: number
  referrer_role: string
}

export interface AddReferralInvitePostData {
  company_id: number
  referrer_id: number
  referrer_role: string
}

export interface UpdateReferralStatusPostData {
  status: boolean
  referral_id: number
  incentive_id: number
  company_id: number
}

export interface AddIncentivePostData {
  description: string
  referrer_incentive: number
  referree_incentive: number
  referree_incentive_type: string
  company_id: number
}

export interface UpdateIncentivePostData {
  company_id: number
  id: number
  description: string
  referrer_incentive: number
  referree_incentive: number
  referree_incentive_type: string
}

export interface MultipleIncentivesPostData {
  company_id: number
  ids?: number[]
  multiple: boolean
}


export interface IncentiveApiResponseData {
  success: IncentiveData[]
  multiple_incentive_config?: boolean;
}

export interface IncentiveData {
  id: number
  description: string
  referrer_incentive: string
  referree_incentive: string
  referree_incentive_type: string
  company_id: number
  editing_enabled?: boolean
  active: boolean
  multiple: boolean
  incentiveName?: string
}


export interface GetReferralLinkPostData {
  referrer_role: string
  referrer_id: number
  company_id: number
}

export interface DecodeReferralLinkResponse {
  company_id: number
  referrer_id: number
  referrer_role: string
  company_name: string
  company_logo: string
  company_website?: string
  referree_incentive?: string
  incentive_type?: string
  referrer_incentive?: string
}

export interface IncentivesApiResponse {
  success: Incentive[]
}

export interface Incentive {
  id: number
  description: string
  incentive_value: string
}

export interface ReferralLeaderboardApiResponse {
  limit: number
  offset: number
  referral_leaderboard: ReferralLeaderboard[]
  total_records: number
}

export interface ReferralLeaderboard {
  first_name: string
  last_name: string
  full_name?: string
  referrer_role: string
  referral_summary?: ReferralSummary
  total_won?: number
  total_referral?: number
  total_invites?: number
  bonus_earned?: string
}

export interface ReferralSummary {
  total_won: number
  total_referral: number
  total_invites: number
  bonus_earned: string
}

export interface GetReferralLinkPostData { 
  referrer_role: string, 
  referrer_id: number, 
  company_id: number,
  payment_method: string,
  amount: number,
  email: string
}

export interface RewardAndCashoutHistoryApiResponse {
  limit: number
  offset: number
  reward_and_cashout_history: RewardAndCashoutHistory[]
  total_records: number
}

export interface RewardAndCashoutHistory {
  type: string
  last_updated: string
  referral_name: string
  amount: number
  reward_balance: number
}

export interface ReferralDashboardApiResponse {
  referral_dashboard: ReferralDashboard
}

export interface ReferralDashboard {
  total_referral: number
  total_converted: number
  total_employee: number
  total_customer: number
}

export interface SalesRepAuditApiResponse {
  sales_rep_audit: SalesRepAudit
}

export interface SalesRepAudit {
  totals: SalesRepAuditTotal[]
  employees: SalesRepAuditEmployee[]
}

export interface SalesRepAuditTotal {
  status: string
  total_count: number
  percentage: number
}

export interface SalesRepAuditEmployee {
  employee_id: number
  firstname: string
  lastname: string
  fullname?: string
  complete_percentage: number
  not_started_percentage: number
  partial_percentage: number
  rank?: number
  completed_count: number
  partial_count: number
  not_started_count: number
}

export interface SalesRepsApiResponse {
  sales_rep_lists: SalesRepresentatives[]
}

export interface SalesRepresentatives {
  ids: number[]
  firstname: string
  lastname: string
  percentage_complete: number
  full_name?: string
}

export interface PaymentIntentResponse {
  id: string
  entity: string
  status: string
  amount: number
  amount_authorized: number
  amount_captured: number
  amount_refunded: number
  amount_voided: number
  amount_disputed: number
  amount_reversed: number
  payment_splits: any[]
  capture: boolean
  currency: string
  external: boolean
  partner_id: string
  business_id: string
  account_id: string
  created_at: string
  updated_at: string
  client_secret: string
  payment_method_types: string[]
}

export interface PaymentCaptureResponse {
  id: string
  entity: string
  status: string
  amount: number
  amount_authorized: number
  amount_captured: number
  amount_refunded: number
  amount_voided: number
  amount_disputed: number
  amount_reversed: number
  payment_intent_id: string
  payment_method_id: string
  payment_method: PCPaymentMethod
  external: boolean
  created_at: string
  updated_at: string
  account_id: string
  billing_details: BillingDetails2
  cvv: string
  avs: string
  auth_code: string
  auth_response_text: string
  card_present: boolean
  settlement_status: string
}

export interface PCPaymentMethod {
  id: string
  entity: string
  created_at: string
  updated_at: string
  payment_method_type: string
  source_type: string
  card: PCCard
  billing_details: PCBillingDetails
}

export interface PCCard {
  last_four_digits: string
  brand: string
  first_six_digits: string
  exp_month: string
  exp_year: string
}

export interface PCBillingDetails {
  address: PCAddress
}

export interface PCAddress {
  postal_code: string
}

export interface BillingDetails2 {
  address: Address2
}

export interface Address2 {
  postal_code: string
}
