import { Component } from "@angular/core";
import {Router} from "@angular/router";
import {Subscription} from "rxjs";
import {ApruvService} from "@apruv/services";
import { KeycloakService } from "keycloak-angular";

@Component({
  selector: "apruv-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent {
  private subscription: Subscription = new Subscription();
  user_name = ""
  bank_name = ""
  type = ""
  mask = ""
  bug_dialog = false
  bug = ""
  is_loading= false;

  constructor (
    private router: Router,
    private apruvService: ApruvService,
    private keycloak: KeycloakService
  ) {
    this.subscription.add(this.getProfileData())

  }

  private getProfileData(): Subscription {
    return this.apruvService
      .GetProfile()
      .subscribe((res) => {
        this.user_name = res.user_name;
        if (res.status) {
          this.bank_name = res.name;
          this.type = res.type;
          this.mask = res.mask;
        }
      })
  }

  deleteBank() {
    this.subscription.add(this.deleteBankData())
  }

  deleteBankData(): Subscription {
    return this.apruvService
      .DeleteBankInfo()
      .subscribe((res) => {
        if (res === "true") {
          this.bank_name = ""
        }
      })
  }

  onUpdatePassword() {
    this.keycloak.login({
      action: 'UPDATE_PASSWORD'
    })
  }

  reportBugDialog() {
    this.bug_dialog = true
  }

  reportBug() {
    this.is_loading = true;

    this.subscription.add(
      this.apruvService.SubmitBug(this.bug).subscribe(() => {
        this.is_loading = false;
        this.bug_dialog = false;
      })
    );
  }

}


