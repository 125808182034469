import jwt_decode, { JwtPayload } from 'jwt-decode';

interface CustomJwtPayload extends JwtPayload {
  role_id: number;
}

export function isTokenValid(token: string): boolean {
  if (!token) {
    // Token is not provided or empty
    return false;
  }

  try {
    const decodedToken: JwtPayload = jwt_decode<JwtPayload>(token);

    if (decodedToken && decodedToken.exp) {
      const expirationTime = decodedToken.exp * 1000; // Convert expiration time to milliseconds
      const currentTime = Date.now();

      return expirationTime > currentTime;
    }
  } catch (error) {
    console.error('Error decoding JWT token:', error);
  }

  return false;
}

export function getUserRoleFromToken(token: string): number | null {
  try {
    const decodedToken: CustomJwtPayload = jwt_decode<CustomJwtPayload>(token);
    return decodedToken.role_id || 0;
  } catch (error) {
    console.error('Error decoding JWT token:', error);
    return null;
  }
}
