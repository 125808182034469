import {Component, Input, OnInit} from '@angular/core';
import { PlaidService } from '@apruv/services';
import {Router} from "@angular/router";

declare let Plaid: any;


@Component({
  selector: "apruv-plaid",
  templateUrl: "./plaid.component.html",
  styleUrls: ["./plaid.component.css"],
})
export class PlaidComponent {
  @Input() editable: boolean;
  handler: any;
  response: any;
  linkToken = "";

  constructor(
    private plaidService: PlaidService,
    private router: Router,
    ) {
    this.editable = false;
  }

  async ngOnInit() {
    this.linkToken = await this.plaidService.createLinkToken();
    this.handler = Plaid.create({
      token: this.linkToken,
      onSuccess: async (publicToken: string, metadata: any) => {
        const access = await this.plaidService.exchangePublicToken(publicToken).toPromise();
        await this.router.navigate(["/dashboard/profile"])
      }
    });

  }

  openPlaid() {
    if (this.editable) {
      this.handler.open();
    }
  }

}
