import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface LoginResponse {
  id: number;
  mfa: boolean;
  email: string;
  token: string;
  temp_pass: boolean;
}

interface VerifyMFAResponse {
  temp_pass: boolean;
  token: string;
}

interface ForgotPasswordResponse {
  success: boolean
}

interface RegistrationResponse {
  success: boolean
}

export interface SalesRegistrationData {
  first_name: string
  last_name: string
  email: string
  phone_number: string
  password: string
  registration_code: string
  encoded_company_id: string
}

interface UpdatePasswordResponse {
  status: boolean
}

interface User {
  FirstName: string;
  LastName: string;
  Email: string;
  UserRole: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = environment.apiUrl;
  public userEmail = "";
  currentUser!: LoginResponse;

  constructor(private http: HttpClient) { }

  login(email: string, password: string): Observable<LoginResponse> {
    const loginData = {
      email: email,
      password: password
    };
    return this.http.post<LoginResponse>(`/api/auth/login`, loginData).pipe(
      tap((user) => this.currentUser = user)
    );
  }


  isLoggedInUser() {
    const token = sessionStorage.getItem('jwt_token');
    if (token) {
      return true
    }
    return false;
  }

  logoutUser() {
    sessionStorage.removeItem('jwt_token');
    location.reload()
  }

  verifyMFA(id: number, code: number): Observable<VerifyMFAResponse> {
    const verificationData = {
      id: id,
      code: code
    };
    return this.http.post<VerifyMFAResponse>(`/api/auth/verify`, verificationData);
  }

  forgotPassword(email: string): Observable<ForgotPasswordResponse> {
    const fpData = {
      email: email
    };
    return this.http.post<ForgotPasswordResponse>(`/api/auth/forgot_password`, fpData);
  }

  updatePassword(old_password: string, new_password: string, token: string): Observable<UpdatePasswordResponse> {
    const fpData = {
      old_password: old_password,
      new_password: new_password
    };
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post<UpdatePasswordResponse>(`/api/auth/update_password`, fpData, { headers });
  }

  registerUser(user: User, companyId: number): Observable<boolean>{
    const fpData = {
      email: user.Email,
      firstname: user.FirstName,
      lastname: user.LastName,
      role: user.UserRole,
      companyId: companyId
    };
    const headers = new HttpHeaders().set('Authorization', `Bearer ${sessionStorage.getItem("jwt_token")}`);
    return this.http.post<boolean>(`/api/auth/register`, fpData, { headers });
  }

  registerTechnician(technician_id: string, registration_key: string, email: string, password: string): Observable<RegistrationResponse>{
    const fpData = {
      technician_id: technician_id,
      registration_key: registration_key,
      email: email,
      password: password,
    };
    return this.http.post<RegistrationResponse>(`/api/auth/register_technician`, fpData);
  }

  registerSalesRep(data: SalesRegistrationData): Observable<RegistrationResponse>{
    return this.http.post<RegistrationResponse>(`/api/frontend/register_sales`, data);
  }
}
