<div>
  <p>Terms and Conditions for APRUV</p>

  <p>Last Updated: 9th August 2023</p>

  <p>
    Welcome to APRUV. By using our services, you are agreeing to the following
    terms and conditions. Please read them carefully.
  </p>

  <p>1. Acceptance of Terms</p>

  <p>
    By accessing or using the APRUV software and services, you accept and agree
    to be bound by these Terms and Conditions (&quot;Terms&quot;). If you do not
    agree to these Terms, you should not use our services.
  </p>

  <p>2. Changes to Terms</p>

  <p>
    APRUV reserves the right to modify or replace these Terms at any time. Users
    will be notified of any changes, and continued use of our services after
    such changes will constitute acceptance of those changes.
  </p>

  <p>3. Service Description</p>

  <p>
    APRUV integrates with service management companies to send review requests
    and facilitates tipping options. Our services might evolve over time, and
    the form and nature of these services may change without prior notice.
  </p>

  <p>4. User Responsibilities</p>

  <p>Users agree to:</p>

  <p>Provide accurate information when prompted by our services.</p>

  <p>
    Use our services in a lawful manner and not for fraudulent or malicious
    activities.
  </p>

  <p>
    Comply with all applicable local, state, and international laws and
    regulations.
  </p>

  <p>5. Payment Processing</p>

  <p>
    APRUV uses Braintree to process payments. When using our services for
    financial transactions, you agree to be bound by Braintree's Terms and
    Conditions and their privacy policy. APRUV is not responsible for any issues
    arising from financial transactions processed by Braintree.
  </p>

  <p>6. Termination</p>

  <p>
    APRUV reserves the right to terminate or suspend access to our services,
    without notice, for behavior that APRUV believes violates these Terms or is
    harmful to other users, third parties, or the business interests of APRUV.
  </p>

  <p>7. Disclaimers and Limitation of Liability</p>

  <p>
    Our services are provided &quot;as is.&quot; APRUV makes no guarantees
    regarding the continuous, uninterrupted or secure access to our services. To
    the maximum extent permitted by law, APRUV disclaims all warranties, express
    or implied, including but not limited to implied warranties of
    merchantability and fitness for a particular purpose.
  </p>

  <p>
    In no event will APRUV be liable for any indirect, incidental, special,
    consequential, or exemplary damages, including but not limited to damages
    for loss of profits, goodwill, use, data, or other intangible losses,
    arising out of or in connection with our services or these Terms.
  </p>

  <p>8. Indemnification</p>

  <p>
    Users agree to indemnify, defend, and hold harmless APRUV, its affiliates,
    officers, directors, employees, agents, and licensors from any claim, cost,
    expense, judgment, or other loss relating to the use of our services,
    including without limitation of the foregoing, any action you take which is
    in violation of these Terms.
  </p>

  <p>9. Governing Law</p>

  <p>
    These Terms are governed by and construed in accordance with the laws of the
    jurisdiction in which APRUV operates, without regard to its conflict of law
    provisions.
  </p>

  <p>10. Feedback and Complaints</p>

  <p>
    Any feedback, comments, or suggestions you may provide regarding APRUV is
    entirely voluntary and we will be free to use such feedback, comments, or
    suggestions as we see fit without any obligation to you.
  </p>

  <p>
    For any complaints, please reach out to us using the contact details
    provided in our Privacy Policy.
  </p>

  <p>11. Miscellaneous</p>

  <p>
    If any provision of these Terms is found to be unenforceable or invalid,
    that provision will be limited or eliminated to the minimum extent necessary
    so that the Terms will otherwise remain in full force and effect.
  </p>

  <p>Contact</p>

  <p>For any queries regarding these Terms, please contact:</p>

  <p>
    APRUV Software Company 3971 Ventura Ave Idaho Falls Idaho, 83401 Email:
    support@apruv.com
  </p>
</div>
