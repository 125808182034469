import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderComponent } from "./header.component";
import { MenuModule } from "primeng/menu";

@NgModule({
  imports: [CommonModule, MenuModule],
  declarations: [HeaderComponent],
  exports:[HeaderComponent]
})
export class HeaderModule {}
