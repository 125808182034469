import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { UserInfo } from '@apruv/services';

export type MenuMode = 'static' | 'overlay' | 'horizontal' | 'slim' | 'slim-plus' | 'reveal' | 'drawer';

export type ColorScheme = 'light' | 'dark';

export type TopbarColorScheme = 'light' | 'dark'| 'transparent';

export interface AppConfig {
    inputStyle: string;
    colorScheme: ColorScheme;
    theme: string;
    ripple: boolean;
    menuMode: MenuMode;
    scale: number;
    menuTheme: ColorScheme;
    topbarTheme: TopbarColorScheme;
}

interface LayoutState {
    staticMenuDesktopInactive: boolean;
    overlayMenuActive: boolean;
    profileSidebarVisible: boolean;
    configSidebarVisible: boolean;
    staticMenuMobileActive: boolean;
    menuHoverActive: boolean;
    topbarMenuActive: boolean;
    sidebarActive: boolean;
    anchored: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class LayoutService {
    private activeItem: MenuItem | undefined
    private activeItemSubject = new Subject<MenuItem | undefined>();
    private companyId = 1
    private companyIdSubject = new Subject<number>();
    private companyName = ""
    private companyNameSubject = new Subject<string>();
    private userInfo = new BehaviorSubject<UserInfo | null>(null);
    private userLoaded = new BehaviorSubject<{loaded: boolean}>({loaded: false});
    userInfo$: Observable<UserInfo | null>;
    userLoaded$: Observable<{loaded: boolean}>;

    constructor() {
        this.userInfo$ = this.userInfo.asObservable();
        this.userLoaded$ = this.userLoaded.asObservable();
    }

    config: AppConfig = {
        ripple: false,
        inputStyle: 'outlined',
        menuMode: 'static',
        colorScheme: 'light',
        theme: 'teal',
        scale: 14,
        menuTheme: 'light',
        topbarTheme: 'transparent'
    };

    state: LayoutState = {
        staticMenuDesktopInactive: false,
        overlayMenuActive: false,
        profileSidebarVisible: false,
        configSidebarVisible: false,
        staticMenuMobileActive: false,
        menuHoverActive: false,
        sidebarActive: false,
        topbarMenuActive: false,
        anchored: false
    };

    private configUpdate = new Subject<AppConfig>();

    private overlayOpen = new Subject<any>();

    private topbarMenuOpen = new Subject<any>();

    topbarMenuOpen$ = this.topbarMenuOpen.asObservable();

    configUpdate$ = this.configUpdate.asObservable();

    overlayOpen$ = this.overlayOpen.asObservable();

    setActiveItem(item: MenuItem) {
        this.activeItem = item;
        this.activeItemSubject.next(item);
    }

    getActiveItem(): MenuItem | undefined {
        return this.activeItem;
    }

    getActiveItemObservable() {
        return this.activeItemSubject.asObservable();
      }

    setCompanyId(item: number | undefined) {
        this.companyId = item || 1;
        this.companyIdSubject.next(this.companyId);
    }

    getCompanyId(): number {
        return this.companyId;
    }

    getCompanyIdObservable() {
        return this.companyIdSubject.asObservable();
      }

    onMenuToggle() {
        if (this.isOverlay()) {
            this.state.overlayMenuActive = !this.state.overlayMenuActive;

            if (this.state.overlayMenuActive) {
                this.overlayOpen.next(null);
            }
        }

        if (this.isDesktop()) {
            this.state.staticMenuDesktopInactive = !this.state.staticMenuDesktopInactive;
        } else {
            this.state.staticMenuMobileActive = !this.state.staticMenuMobileActive;

            if (this.state.staticMenuMobileActive) {
                this.overlayOpen.next(null);
            }
        }
    }

    onTopbarMenuToggle() {
        this.state.topbarMenuActive = !this.state.topbarMenuActive;
        if (this.state.topbarMenuActive) {
            this.topbarMenuOpen.next(null);
        }
    }

    onOverlaySubmenuOpen() {
        this.overlayOpen.next(null);
    }

    showSidebar() {
        this.state.profileSidebarVisible = true;
    }

    showConfigSidebar() {
        this.state.configSidebarVisible = true;
    }

    isOverlay() {
        return this.config.menuMode === 'overlay';
    }

    isDesktop() {
        return window.innerWidth > 991;
    }

    isSlim() {
        return this.config.menuMode === 'slim';
    }

    isSlimPlus() {
        return this.config.menuMode === 'slim-plus';
    }

    isHorizontal() {
        return this.config.menuMode === 'horizontal';
    }

    isMobile() {
        return !this.isDesktop();
    }

    onConfigUpdate() {
        this.configUpdate.next(this.config);
    }

    setActiveUser(user: UserInfo) {
        this.userInfo.next(user);
    }

    setUserLoadedStatus(isLoaded: boolean) {
        this.userLoaded.next({loaded: isLoaded});
    }
}
