import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "apruv-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.css"],
})
export class ModalComponent implements OnInit {
  @Input() visible: boolean;
  @Output() okBtnClick = new EventEmitter();
  constructor() {
    this.visible = false;
  }

  ngOnInit() {}
}
