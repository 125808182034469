import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CompaniesResponse, CompanyResponse, CompanyEdit, CompanyEditResponse, CompanySave, CompanySaveResponse, locationsResponse, CompaniesAPIResponse, locationListResponse, LocationRequest } from "./frontend.models";

@Injectable({
    providedIn: 'root'
})
export class FrontendCompanyService {
    constructor(private http: HttpClient) { }

    getCompanies(): Observable<CompaniesResponse> {
        return this.http.post<CompaniesResponse>(`/api/frontend/get_companies`, null);
    }

    getCompanyData(companyId: number): Observable<CompanyResponse> {
        const fpData = {
            company_id: companyId
        };
        return this.http.post<CompanyResponse>(`/api/frontend/get_company_data`, fpData);
    }

    EditCompanyData(company: CompanyEdit): Observable<CompanyEditResponse> {
        const fpData = {
            company: company
        };
        return this.http.post<CompanyEditResponse>(`/api/frontend/edit_company_data`, fpData);
    }

    SaveCompany(company: CompanySave): Observable<CompanySaveResponse> {
        const fpData = {
            company: company
        };
        return this.http.post<CompanySaveResponse>(`/api/frontend/save_company_data`, fpData);
    }

    getHlCompanies(): Observable<CompaniesResponse> {
        return this.http.post<CompaniesResponse>(`/api/frontend/hl_get_companies`, null);
    }

    getHlCompaniesAPI(): Observable<CompaniesAPIResponse> {
        return this.http.post<CompaniesAPIResponse>(`/api/frontend/hl_api_get_companies`, null);
    }

    getHLCompanyData(companyId: string): Observable<CompanyResponse> {
        const fpData = {
            company_id: companyId
        };
        return this.http.post<CompanyResponse>(`/api/frontend/hl_get_company_data`, fpData);
    }

    getLocationList(companyId: number): Observable<locationsResponse> {
        const fpData = {
            company_id: companyId,
        };
        return this.http.post<locationsResponse>(`/api/frontend/get_locations`, fpData);
    }

    createLocation(companyId: number, payload: LocationRequest): Observable<locationListResponse> {
        return this.http.post<locationListResponse>(`/api/frontend/companies/${companyId}/locations`, payload);
    }

    updateLocation(companyId: number, locationId: number, payload: LocationRequest): Observable<locationListResponse> {
        return this.http.put<locationListResponse>(`/api/frontend/companies/${companyId}/locations/${locationId}`, payload);
    }

    getLocations(companyId: number): Observable<locationListResponse> {
        return this.http.get<locationListResponse>(`/api/frontend/companies/${companyId}/locations`);
    }
}