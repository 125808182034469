import {ChangeDetectorRef, Component} from "@angular/core";
import {Subscription} from "rxjs";
import {ApruvService, FeedbackHistory} from "@apruv/services";
import { KeycloakService } from "keycloak-angular";
import { LayoutService } from "../../layout/service/layout.service";

interface JwtPayload {
  user_id: number;
  email: string;
  role_id: number;
  company_id: number;
  exp: number;
}

@Component({
  selector: "apruv-feedback-dashboard",
  templateUrl: "./feedback.component.html",
  styleUrls: ["./feedback.component.css"],
})
export class FeedbackDashboardComponent {
  today = new Date();
  dateOptions = { weekday: 'long' as const, month: 'long' as const, day: 'numeric' as const };
  formattedDate = '';
  rating_string = '';
  rating_percentage = '';
  limit = 10
  itemsPerPage = 10;
  moreActivity = true;
  feedbackByDate: FeedbackHistory[] = [];
  role= 0
  currenUserSubs!: Subscription;

  constructor(
    private apruvService: ApruvService,
    private keyCloak: KeycloakService,
    private layoutService: LayoutService
  ) {
    this.formattedDate = this.today.toLocaleDateString('en-US', this.dateOptions);
    this.currenUserSubs = this.layoutService.userInfo$.subscribe(u => {
      if(u) {
        this.rating_string = sessionStorage.getItem('rating_string') || ""
        this.rating_percentage = sessionStorage.getItem('rating_percentage') || ""
        this.role = u.RoleID;
        this.setReviewData()
      }
    })
  }

  private setReviewData(): Subscription {
    return this.apruvService
      .feedbackHistory(this.itemsPerPage, 0, false)
      .subscribe((res) => {
        if (res.feedback_count < this.itemsPerPage) {
          this.moreActivity = false;
        }
        const feedbackMap: { [key: string]: FeedbackHistory } = {};

        if(!res?.feedback_history?.length) {
          this.feedbackByDate = [];
          return;
        }

        res.feedback_history.forEach(feedbackHistory => {
          const date = feedbackHistory.date;

          if (!feedbackMap[date]) {
            feedbackMap[date] = {
              date,
              feedback: []
            };
          }
          feedbackMap[date].feedback.push(...feedbackHistory.feedback);
        })
        this.feedbackByDate = Object.values(feedbackMap).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
      });
  }

  getRatingImage(rating: number): string {
    if (rating == 1) {
      return "/assets/svg/emotion-unhappy-line.svg";
    } else if (rating == 2) {
      return "/assets/svg/orange_emoji.svg";
    } else if (rating == 3) {
      return "/assets/svg/green_smile.svg";
    } else {
      return "/assets/svg/emotion-laugh-line.svg";
    }
  }

  loadMoreTransactions(): void {
    this.itemsPerPage += this.limit;
    this.setReviewData();
  }
}


