<div class="layout-sidebar" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
    <div class="sidebar-header">
        <a [routerLink]="['/']" class="app-logo cursor-pointer">
            <label class="font-bold text-5xl" style="color: var(--primary-700);">Apruv </label>
        </a>
        <button class="layout-sidebar-anchor p-link" type="button" (click)="anchor()"></button>
    </div>

    <div #menuContainer  class="layout-menu-container">
        <apruv-menu></apruv-menu>
    </div>
</div>
