import {Component, OnInit} from "@angular/core";
import { ApruvService, TransactionHistory, Transaction } from "@apruv/services"
import { KeycloakService } from "keycloak-angular";
import {Subscription} from "rxjs";
import { LayoutService } from "../../layout/service/layout.service";

interface JwtPayload {
  user_id: number;
  email: string;
  role_id: number;
  company_id: number;
  external_user_id: number;
  exp: number;
}

@Component({
  selector: "apruv-balance",
  templateUrl: "./balance.component.html",
  styleUrls: ["./balance.component.css"],
})
export class BalanceComponent {
  private subscription: Subscription = new Subscription();
  current_balance= 0;
  current_balance_check= 0;
  method_dialog= false;
  pending_balance = 0;
  total_ratings = 0;
  rating_percentage = 0;
  total_earned = 0;
  average_tip = 0;
  rating_string = "";
  error_message= "";
  is_loading= false;
  active_plaid = false;
  transactionsByDate: TransactionHistory[] = [];
  currentPage = 0;
  limit = 10
  itemsPerPage = 10;
  moreActivity = true;
  noTransaction = true;
  cashOut = false;
  currenUserSubs!: Subscription;

  constructor(
    private apruvService: ApruvService,
    private keyCloak: KeycloakService,
    private layoutService: LayoutService
  ) {
    this.currenUserSubs = this.layoutService.userInfo$.subscribe(u => {
      if(u) {
        this.subscription.add(this.setBalanceData());
        this.subscription.add(this.setTransactionData());
      }
    })
  }

  private setBalanceData(): Subscription {
    return this.apruvService
      .balanceOverview()
      .subscribe((res) => {
        this.active_plaid = res.plaid
        this.pending_balance = parseFloat(res.balance.status_0_total);
        this.current_balance = parseFloat(res.balance.status_1_total);
        this.total_ratings = res.rating.total_ratings
        this.rating_percentage = parseFloat(res.rating.rating_percentage)
        this.rating_string = this.getRatingString(this.rating_percentage)
        sessionStorage.setItem("rating_string", this.rating_string)
        sessionStorage.setItem("rating_percentage", this.rating_percentage.toString())
        this.total_earned = parseFloat(res.transaction.total_amount)
        this.average_tip = parseFloat(res.transaction.average_amount)
        if (this.current_balance > 0) {
          this.cashOut = true;
        }
      });
  }

  private setTransactionData(): Subscription {
    return this.apruvService
      .transactionHistory(this.itemsPerPage, 0)
      .subscribe((res) => {
        if (res.transaction_count > 0) {
          this.noTransaction = false;
        }
        if (res.transaction_count <= this.itemsPerPage) {
          this.moreActivity = false;
        }
        const transactionsMap: { [key: string]: TransactionHistory } = {};

        res.transaction_history?.forEach(transactionHistory => {
          const date = transactionHistory.date;

          if (!transactionsMap[date]) {
            transactionsMap[date] = {
              date,
              transactions: []
            };
          }
          transactionsMap[date].transactions.push(...transactionHistory.transactions);
        })
        this.transactionsByDate = Object.values(transactionsMap).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
      });
  }

  getRatingString(rating: number): string {
    if (rating < 25) {
      return 'Bad';
    } else if (rating < 50) {
      return 'Okay';
    } else if (rating < 75) {
      return 'Good';
    } else {
      return 'Great';
    }
  }

  getRatingImage(rating: number, statusGroup: number): string {
    if (rating == 1) {
      return "/assets/svg/emotion-unhappy-line.svg";
    } else if (rating == 2) {
      return "/assets/svg/orange_emoji.svg";
    } else if (rating == 3) {
      return "/assets/svg/green_smile.svg";
    } else if (statusGroup == 2) {
      return "/assets/svg/building.svg";
    } else {
      return "/assets/svg/emotion-laugh-line.svg";
    }
  }

  getTransactionStatus(statusGroup: number): string {
    if (statusGroup == 0) {
      return "Transaction Pending";
    } else if (statusGroup == 1) {
      return "Transaction Complete";
    } else if (statusGroup == 2) {
      return "Deposit";
    } else {
      return "";
    }
  }

  loadMoreTransactions(): void {
    this.itemsPerPage += this.limit;
    this.setTransactionData();
  }

  cashOutDialog(): void {
    this.method_dialog = true;
  }

  cashOutButton(method: string): void {
    this.is_loading = true;

    this.subscription.add(
      this.apruvService.CashOutCurrentBalance().subscribe((res) => {
        this.current_balance_check = parseFloat(res.balance.status_1_total);

        if (this.current_balance != this.current_balance_check) {
          this.error_message = "Balance Error. Please contact us at admin@apruv.io";
        } else {
          this.subscription.add(
            this.apruvService.CashOut(method).subscribe((res) => {
              this.is_loading = false;
              this.method_dialog = false;

              if (res.cashedOut) {
                location.reload();
              } else {
                this.error_message = "Internal Error: Unable to Cash Out at this time. Please try again later.";
              }
            })
          );
        }
      })
    );
  }


}


