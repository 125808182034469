import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UserList, UserLocation, CrmUsers, DupUserLocation, DupUserInfo, UserProfile, TechUserList, TechLocation } from "./frontend.models";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class FrontendUserService {
    private userInfo: any;

    constructor(private http: HttpClient) { }

    get currentUserInfo() {
        return this.userInfo;
    }

    set currentUserInfo(user) {
        this.userInfo = user;
    }

    getUserList(locationList: number[], companyId: number): Observable<UserList[]> {
        const fpData = {
            location_list: locationList,
            company_id: companyId
        };
        return this.http.post<UserList[]>(`/api/frontend/get_users`, fpData);
    }

    createUser(user: UserList): Observable<UserList> {
        const headers = new HttpHeaders().set('Authorization', `Bearer ${sessionStorage.getItem("jwt_token")}`);
        return this.http.post<UserList>(`/api/frontend/register_admin_user`, user, { headers });
    }

    updateUser(user: UserList): Observable<UserList> {
        return this.http.post<UserList>(`/api/frontend/save_admin_user`, user);
    }

    updateTechUser(user: {old_email: string, company_id: number, technician_ids: number[], user: TechUserList}): Observable<TechUserList> {
        return this.http.post<TechUserList>(`/api/frontend/save_tech_user`, user);
    }

    getAdminUserLocations(user_id: number, company_id: number): Observable<UserLocation[]> {
        return this.http.post<UserLocation[]>(`/api/frontend/get_admin_user_locations`, { user_id, company_id });
    }

    getTechUserLocations(data: { company_id:number, technician_ids: number[]}): Observable<TechLocation[]> {
        return this.http.post<TechLocation[]>(`/api/frontend/get_tech_user_locations`, data);
    }

    getCrmUsers(location_id: number): Observable<CrmUsers[]> {
        return this.http.post<CrmUsers[]>(`/api/frontend/get_crm_users`, { location_id });
    }

    addAdminUserLocation(userLocation: {
        user_id: number,
        company_id: number,
        location_id: number,
        status: boolean,
    }): Observable<UserLocation[]> {
        return this.http.post<UserLocation[]>(`/api/frontend/add_admin_user_location`, userLocation);
    }

    getDupUsers(location_ids: number[]): Observable<DupUserLocation[]> {
        return this.http.post<DupUserLocation[]>(`/api/frontend/get_user_duplicates_locations`, { location_list: location_ids });
    }

    getTechUsers(location_list: any[], company_id: number): Observable<TechUserList[]> {
        const fpData = {
            location_list,
            company_id
        };
        return this.http.post<TechUserList[]>(`/api/frontend/get_tech_users`, fpData);
    }

  getTechs(location_list: any[], company_id: number): Observable<TechUserList[]> {
    const fpData = {
      location_list,
      company_id
    };
    return this.http.post<TechUserList[]>(`/api/frontend/get_techs`, fpData);
  }

    getAdminUsers(location_list: number[], company_id: number): Observable<UserList[]> {
        const fpData = {
            location_list,
            company_id
        };
        return this.http.post<UserList[]>(`/api/frontend/get_admin_users`, fpData);
    }

    getDupUser(duplicate_id: number): Observable<DupUserInfo[]> {
        return this.http.post<DupUserInfo[]>(`/api/frontend/get_user_duplicate`, { user_duplicate_id: duplicate_id });
    }

    resolveUserDup(resolve_data: { user_duplicate_id: number, technician_id: number, action: 'dup' | 'non-dup' }): Observable<any> {
        return this.http.post<any>(`/api/frontend/user_duplicate_action`, resolve_data);
    }

    getUserProfile() {
        return this.http.post<any>(`/api/frontend/get_user_profile`, {});
    }

    saveUserProfile(user_data: UserProfile) {
        return this.http.post<any>(`/api/frontend/save_user_profile`, user_data);
    }

    getUserInfo() {
        return this.http.post<any>(`/api/frontend/get_user_info`, {});
    }

    userSync() {
        return this.http.post<any>(`/api/frontend/user_sync`, {});
    }
}
