import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FieldRoutesResponse, FieldRoutes, Locations, CompanyIntegration, FieldRoutesAdd, IntegrationsListResponse, LocationIntegrationResponse, LocationIntegrationAddResponse } from "./frontend.models";

@Injectable()
export class FrontendRoutesService {
    constructor(private http: HttpClient) { }

    getFieldRoutesData(integrationDataId: number): Observable<FieldRoutesResponse> {
        const fpData = {
            integration_data_id: integrationDataId
        };
        return this.http.post<FieldRoutesResponse>(`/api/frontend/get_fieldRoutes_data`, fpData);
    }

    saveFieldRoutesData(company_id: number, FieldRoutesData: FieldRoutes, FieldRoutesLocations: Locations[], company_integration: CompanyIntegration): Observable<boolean> {
        const fpData = {
            company_id: company_id,
            field_routes_data: FieldRoutesData,
            field_routes_location_data: FieldRoutesLocations,
            company_integration: company_integration,
        };
        return this.http.post<boolean>(`/api/frontend/save_fieldRoutes_data`, fpData);
    }

    addFieldRoutesIntegration(FieldRoutesData: FieldRoutesAdd, FieldRoutesLocations: Locations[], status: boolean, integration_id: number, company_id: number): Observable<boolean> {
        const fpData = {
            field_routes_data: FieldRoutesData,
            field_routes_location_data: FieldRoutesLocations,
            status: status,
            integration_id: integration_id,
            company_id: company_id
        };
        return this.http.post<boolean>(`/api/frontend/add_fieldRoutes_integration`, fpData);
    }

    getIntegrations(): Observable<IntegrationsListResponse> {
        return this.http.post<IntegrationsListResponse>(`/api/frontend/get_integrations`, null);
    }

    getFieldRouteLocations(ApiBaseUrl: string, SearchApiKey: string, SearchApiToken: string): Observable<Locations[]> {
        const fpData = {
            ApiBaseUrl: ApiBaseUrl,
            SearchApiKey: SearchApiKey,
            SearchApiToken: SearchApiToken
        };
        return this.http.post<Locations[]>(`/api/frontend/get_field_route_locations`, fpData);
    }

    getIntegration(id: number, integrationDataId: number): Observable<LocationIntegrationResponse> {
        return this.http.get<LocationIntegrationResponse>(`/api/frontend/integrations/${id}/company_integrations/${integrationDataId}`);
    }

    createIntegration(id: number, payload: LocationIntegrationAddResponse): Observable<LocationIntegrationResponse> {
        return this.http.post<LocationIntegrationResponse>(`/api/frontend/integrations/${id}/company_integrations`, payload);
    }
    
    updateIntegration(id: number, integrationDataId: number, payload: LocationIntegrationAddResponse): Observable<LocationIntegrationResponse> {
        return this.http.put<LocationIntegrationResponse>(`/api/frontend/integrations/${id}/company_integrations/${integrationDataId}`, payload);
    }
}