<div class="footer-container grid">
    <a class="text-center text-decoration-none col-3 " routerLink='./balance'   routerLinkActive="activeTab">
        <div class="active-border">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="#60608A" xmlns="http://www.w3.org/2000/svg">
                <path d="M22 6.5H15C13.4087 6.5 11.8826 7.13214 10.7574 8.25736C9.63214 9.38258 9 10.9087 9 12.5C9 14.0913 9.63214 15.6174 10.7574 16.7426C11.8826 17.8679 13.4087 18.5 15 18.5H22V20.5C22 20.7652 21.8946 21.0196 21.7071 21.2071C21.5196 21.3946 21.2652 21.5 21 21.5H3C2.73478 21.5 2.48043 21.3946 2.29289 21.2071C2.10536 21.0196 2 20.7652 2 20.5V4.5C2 4.23478 2.10536 3.98043 2.29289 3.79289C2.48043 3.60536 2.73478 3.5 3 3.5H21C21.2652 3.5 21.5196 3.60536 21.7071 3.79289C21.8946 3.98043 22 4.23478 22 4.5V6.5ZM15 8.5H23V16.5H15C13.9391 16.5 12.9217 16.0786 12.1716 15.3284C11.4214 14.5783 11 13.5609 11 12.5C11 11.4391 11.4214 10.4217 12.1716 9.67157C12.9217 8.92143 13.9391 8.5 15 8.5ZM15 11.5V13.5H18V11.5H15Z"/>
                </svg>
            <p class="title text-sm">Balance</p>
        </div>
    </a>

    <a class="text-center text-decoration-none col-3 " routerLink='./feedback'   routerLinkActive="activeTab">
      <div class="active-border">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="#60608A" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
        </svg>
        <p class="title text-sm">Feedback</p>
      </div>
    </a>

    <a class=" text-center text-decoration-none col-3" routerLink='./leaderboard' routerLinkActive="activeTab">
        <div class="">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="#60608A" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 17.438V19.5H18V21.5H6V19.5H11V17.438C9.0667 17.1942 7.28882 16.2533 6 14.7917C4.71119 13.3302 4.00003 11.4486 4 9.5V3.5H20V9.5C20 11.4486 19.2888 13.3302 18 14.7917C16.7112 16.2533 14.9333 17.1942 13 17.438ZM1 5.5H3V9.5H1V5.5ZM21 5.5H23V9.5H21V5.5Z"/>
                </svg>
            <p class="title text-sm">Leaderboard</p>
        </div>
    </a>

    <a class=" text-center text-decoration-none col-3" routerLink='./profile' routerLinkActive="activeTab">
        <div class="">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="#60608A"  xmlns="http://www.w3.org/2000/svg">
                <path d="M4 22.5C4 20.3783 4.84285 18.3434 6.34315 16.8431C7.84344 15.3429 9.87827 14.5 12 14.5C14.1217 14.5 16.1566 15.3429 17.6569 16.8431C19.1571 18.3434 20 20.3783 20 22.5H18C18 20.9087 17.3679 19.3826 16.2426 18.2574C15.1174 17.1321 13.5913 16.5 12 16.5C10.4087 16.5 8.88258 17.1321 7.75736 18.2574C6.63214 19.3826 6 20.9087 6 22.5H4ZM12 13.5C8.685 13.5 6 10.815 6 7.5C6 4.185 8.685 1.5 12 1.5C15.315 1.5 18 4.185 18 7.5C18 10.815 15.315 13.5 12 13.5ZM12 11.5C14.21 11.5 16 9.71 16 7.5C16 5.29 14.21 3.5 12 3.5C9.79 3.5 8 5.29 8 7.5C8 9.71 9.79 11.5 12 11.5Z"/>
                </svg>
            <p class="title text-sm">Profile</p>
        </div>
    </a>

</div>
