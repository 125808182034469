<div>
  <p>Privacy Policy for APRUV</p>

  <p>Last Updated: 9th August 2023</p>

  <p>
    Welcome to APRUV. Your privacy is of utmost importance to us. This Privacy
    Policy explains what information we collect, how we use it, and your rights
    and choices regarding this information. By using our services, you agree to
    the terms of this Privacy Policy.
  </p>

  <p>1. Introduction</p>

  <p>
    APRUV integrates with service management companies to facilitate the sending
    of review requests and tip options to clients. This policy is tailored to
    explain how we manage and safeguard data collected through this integration.
  </p>

  <p>2. Information We Collect</p>

  <p>We may collect information in the following ways:</p>

  <p>
    Automatically from Service Management Companies: This may include customer
    names, phone numbers, email addresses, service details, and service dates.
  </p>

  <p>
    Directly from Users: When users respond to review requests, provide
    feedback, or choose to leave a tip.
  </p>

  <p>
    From Cookies and Similar Technologies: Information about your device, IP
    address, browsing history on our platform, and how you interact with our
    services.
  </p>

  <p>3. How We Use the Information</p>

  <p>We use the collected information to:</p>

  <p>Send text and email review requests.</p>

  <p>Facilitate the tip leaving process.</p>

  <p>
    Improve our services, including troubleshooting, data analysis, and
    research.
  </p>

  <p>
    Comply with legal requirements and protect the rights, safety, and property
    of APRUV, our users, and the public.
  </p>

  <p>4. Sharing of Information</p>

  <p>We do not sell your personal information. We may share information:</p>

  <p>
    With service providers who help us run our services, but they cannot use
    your information for their own purposes without your consent.
  </p>

  <p>
    If required by law, in connection with any legal proceedings, and to
    establish, exercise, or defend our legal rights.
  </p>

  <p>
    In connection with a sale, merger, or acquisition of all or part of APRUV.
  </p>

  <p>5. Data Retention</p>

  <p>
    We keep your data for as long as it's necessary to fulfill the purposes for
    which it was collected or to comply with legal, regulatory, or internal
    policy requirements.
  </p>

  <p>6. Your Rights and Choices</p>

  <p>You may:</p>

  <p>Request access to your personal information.</p>

  <p>Ask for corrections, updates, or deletion of your data.</p>

  <p>Opt-out of receiving review requests.</p>

  <p>Raise concerns about our use of your data.</p>

  <p>7. Security</p>

  <p>
    We implement stringent security measures to protect your data from
    unauthorized access, alteration, disclosure, or destruction. These measures
    include encryption, secure servers, and regular audits.
  </p>

  <p>8. Cookies and Similar Technologies</p>

  <p>
    We use cookies and similar technologies to enhance user experience, for
    analytics, and for advertisement targeting. You can configure your browser
    to reject all or some cookies or notify you when cookies are set.
  </p>

  <p>9. Links to Other Websites</p>

  <p>
    Our service may contain links to other sites. We're not responsible for the
    privacy practices of those sites. This policy only applies to information
    collected by APRUV.
  </p>

  <p>10. Changes to This Privacy Policy</p>

  <p>
    We may update this Privacy Policy from time to time. Any changes will be
    posted on this page with the updated revision date.
  </p>

  <p>11. Contact Us</p>

  <p>
    If you have questions or concerns about this Privacy Policy or our
    practices, please contact us at:
  </p>

  <p>
    APRUV Software Company 3971 Ventura Ave Idaho Falls Idaho, 83401 Email:
    support@apruv.com
  </p>
</div>
