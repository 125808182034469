<div class="text-center pt-5 pb-3-2">
  <h4 class="text-white text-xxl font-semibold">Welcome {{ user_name }}!</h4>
</div>
<!--        <div class="pt-5">-->
<!--            <h6 class="text-white text-lg cursor-pointer"><img src="/assets/svg/left-arrow.svg" class="mx-2" alt="">Go-->
<!--                back</h6>-->
<!--        </div>-->
<div class="text-center pt-5 pb-3">
  <h4 class="text-white text-xlg mb-2 font-semibold">Connected Bank</h4>
  <div *ngIf="bank_name" class="dark-border px-2 py-3 mx-2 mt-2">
    <div class="text-white flex flex-wrap border-rounded" style="align-items: center;">
      <div class="flex-item p-1 col-2" style="flex: 17%;">
        <img src="/assets/svg/building.svg" alt="">
      </div>
      <div class="flex-item p-1 col-7 line-height-2 mt-1 " style="flex: 56%;">
        <span class="font-normal text-lg">{{ bank_name }}</span><br>
        <span class="secondary-color text-sm">{{ type }} ***{{ mask }}</span>
      </div>
      <div (click)=deleteBank() class="flex-item p-1 col-2" style="flex: 16%; cursor: pointer;">
        <span class="pi pi-trash" style="font-size: 1.5rem; color: indianred"></span>
      </div>
    </div>
  </div>
</div>
<div>
  <apruv-plaid [editable]="bank_name === ''" class="mt-3"></apruv-plaid>
</div>
<h1 *ngIf="!bank_name" class="text-white text-center align-items-center font-semibold text-lg mx-3 mt-0 line-height-2">
  Link your bank account to Plaid and
  cash out your
  tips
</h1>
<h2 class="text-white text-center align-items-center font-semibold text-sm mx-3 mt-0 line-height-2">
  * Some banks may not be supported on Apruv yet (such as Capital One, Chase Bank, Wells Fargo, Bank of America,
  Citibank, PNC Financial Services, USAA, and American Express are not supported). If you have a bank that is not
  supported, please select 'Zelle' as your payout option after clicking 'Cash out' in the Balance tab.
</h2>
<div class="my-5 mx-2 pt-10">
  <div class="text-center pt-5 pb-3-2">
    <h4 class="text-white text-xxl font-semibold">Password Management</h4>
  </div>
  <apruv-button class="w-full active" styleClass="w-full active text-lg" (click)="onUpdatePassword()">
    <span> Update Password</span>
  </apruv-button>
</div>
<div>
  <!--        <apruv-button class="w-full active" styleClass="w-full active text-lg">Watch a Tutorial <img-->
  <!--          src="/assets/svg/Vector.svg" class="mx-2"></apruv-button>-->
</div>

<apruv-modal [visible]="bug_dialog">
  <div class="flex-container mt-4 mb-4">
    <h6 class="text-white flex-grow">Report Bug: </h6>
    <h5 class="text-white cursor-pointer m-0 secondary-color" (click)="bug_dialog = false">X</h5>
  </div>
  <div class="text-center">
    <span class="p-float-label">
      <textarea id="float-input" rows="7" cols="30" [(ngModel)]="bug" pInputTextarea></textarea>
      <label for="float-input">Describe bug, in detail, here.</label>
    </span>
  </div>
  <div>
    <apruv-button class="w-full active mx-2" styleClass="w-full active text-lg mx-2" (click)="reportBug()">
      <span> Submit</span>
    </apruv-button>
  </div>
</apruv-modal>
<div class="relative">
  <p-progressSpinner *ngIf="is_loading" class="loading-spinner top-50 left-50 mt-8  absolute z-2"></p-progressSpinner>
</div>