import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TermsOfServiceComponent } from "./terms-of-service.component";

@NgModule({
  imports: [CommonModule],
  declarations: [TermsOfServiceComponent],
  exports: [TermsOfServiceComponent],
})
export class TermsOfServiceModule {}
