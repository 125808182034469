import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ModalComponent } from "./modal.component";
import { DialogModule } from "primeng/dialog";
import { ButtonsModule } from "../buttons/buttons.module";
@NgModule({
  imports: [CommonModule, DialogModule, ButtonsModule],
  declarations: [ModalComponent],
  exports: [ModalComponent],
})
export class ModalModule {}
