import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { DashboardComponent } from "./dashboard.component";
import { LeaderboardComponent } from "./leaderboard/leaderboard.component";
import { BalanceComponent } from "./balance/balance.component";
import { ProfileComponent } from "./profile/profile.component";
import {FeedbackDashboardComponent} from "./feedback/feedback.component";
import { DashboardContainerComponent } from "./dashboard-container.component";

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: "",
        component: DashboardContainerComponent,
        children: [
          {
            path: "",
            component: DashboardComponent,
          },
          {
            path:'leaderboard',
            component:LeaderboardComponent
          },
          {
            path:'balance',
            component:BalanceComponent
          },
          {
            path:'feedback',
            component:FeedbackDashboardComponent
          },
          {
            path:'profile',
            component:ProfileComponent
          }
        ]
      }
    ]),
  ],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
