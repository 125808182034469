import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BugInfo, CompanyDashData, DashboardData, ReviewsData } from "./frontend.models";
import { DateTime } from "luxon";

@Injectable()
export class FrontendBugService {
    constructor(private http: HttpClient) { }

    getCurrentBugData(): Observable<{ bug_data: BugInfo[] }> {
        return this.http.post<{ bug_data: BugInfo[] }>(`/api/frontend/current_bug_data`, null);
    }

    getPastBugData(): Observable<{ bug_data: BugInfo[] }> {
        return this.http.post<{ bug_data: BugInfo[] }>(`/api/frontend/past_bug_data`, null);
    }

    markBugAsResolved(bugId: number) {
        return this.http.post<DashboardData>(`/api/frontend/mark_as_resolved`, { bug_id: bugId });
    }
}